<template>
    <div class="block-group__items">
        <dropdown-menu
            :items="$root.payments"
            v-model="$root.payment"
            :is-payment="true"
        />
        <div class="block-group__item" v-if="isCash">
            <input type="number" class="block-group__input" v-model="$root.payment.cash" required>
            <label class="block-group__label">Нужна сдача с</label>
        </div>
        <div class="block-group__item block-group__item--cash" v-if="isCash">
            <button class="block-group__cash"
                    v-for="banknote in $root.payment.banknotes"
                    @click="setCash(banknote)">{{ banknote }} ₽</button>
            <button class="block-group__cash" @click="setCash(0)">Без сдачи</button>
        </div>
    </div>
</template>

<script>
export default {
    computed: {
        isCash() {
            return !!this.$root.payment?.banknotes;
        },
    },
    methods: {
        setCash(cash) {
            this.$root.payment.cash = cash;
        }
    }
}
</script>

<style scoped>

</style>
