import jQuery from 'jquery';
global.$ = global.jQuery = jQuery;

import './jquery.cookie';
import './jquery.datetimepicker.full.min';
jQuery.datetimepicker.setLocale('ru');

// Moment.js https://momentjs.com
import moment from 'moment';
global.moment = moment;
moment.locale('ru');

// Vue.js (2) https://v2.vuejs.org
import Vue from 'vue';
global.Vue = Vue;
Vue.prototype.$eventBus = new Vue(); // Global event bus

// vue-cookies https://github.com/cmp-cc/vue-cookies
import VueCookie from 'vue-cookie';
Vue.use(VueCookie);

// Vue-Lazyload https://github.com/hilongjw/vue-lazyload
import VueLazyload from 'vue-lazyload';
Vue.use(VueLazyload);

// TODO проверить использование
import deliveryZonesMixin from './mixins/deliveryZones.js';
Vue.mixin(deliveryZonesMixin);

import { cookiesIsEnabled } from './helpers';

// axios https://axios-http.com/docs/intro
import axios from 'axios';
global.axios = axios;
global.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
global.axios.defaults.headers.common['X-CSRF-TOKEN'] = document.querySelector('meta[name="csrf-token"]')?.getAttribute('content') ?? '';

// Проверка доступности cookie
axios.interceptors.request.use(function (config) {
    if (!cookiesIsEnabled()) {
        const controller = new AbortController();
        controller.abort();

        return {
            ...config,
            signal: controller.signal
        };
    }

    return config;
}, function (error) {
    return Promise.reject(error);
});

// Список товаров в корзине (выводится в мини корзине и на оформлении заказа)
import basketList from './components/cart/basketList';
Vue.component('basketList', basketList);

import products from "./components/products.vue";
Vue.component('products', products);

// Оформление заказа
import MakingOrder from "./components/cart/MakingOrder.vue";
Vue.component('MakingOrder', MakingOrder);

import paymentMethod from "./components/cart/paymentMethod.vue";
Vue.component('paymentMethod', paymentMethod);

import modalMobile from "./components/cart/modalMobile.vue";
Vue.component('paymentMethodMobile', modalMobile);

import cookieMessage from "./components/cookieMessage.vue";
Vue.component('cookieMessage', cookieMessage);

import dropdownMenu from "./components/cart/dropdownMenu.vue";
Vue.component('dropdownMenu', dropdownMenu);

import promocode from "./components/cart/promocode.vue";
Vue.component('promocode', promocode);

import typePayment from "./components/cart/typePayment.vue";
Vue.component('typePayment', typePayment);

import scrollMenu from "./components/scrollMenu.vue";
Vue.component('scrollMenu', scrollMenu);

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/ru';
Vue.component('datePicker', DatePicker);

import PaymentMethod from "./components/cart/paymentMethod.vue";
Vue.component('paymentMethod', PaymentMethod);

import shopMap from "./components/cart/shopMap.vue";
Vue.component('shopMap', shopMap);

// Счётчик количества товара и кнопка добавления/удаления
import productCounter from './components/products/productCounter';
Vue.component('productCounter', productCounter);

// Карточка товара (выводится на домашней странице)
import productCard from './components/products/productCard';
Vue.component('productCard', productCard);

// Отображение данных о товаре (выводится в модальном окне товара и сингле)
import productView from './components/products/productView';
Vue.component('productView', productView);

// Слайдер товаров (выводится на оформлении заказа для сопутствующих)
import productSlider from './components/products/productSlider';
Vue.component('productSlider', productSlider);

// Модальное окно с выбором адреса на яндекс карте
import yandexAddressPicker from './components/order-types/selectOrderTypeModal/yandexAddressPicker';
Vue.component('yandexAddressPicker', yandexAddressPicker);

// Карта зон доставки
import deliveryZonesMap from './components/static-pages/deliveryZonesMap';
Vue.component('deliveryZonesMap', deliveryZonesMap);

// Мини корзина
import miniBasket from './components/mini-basket';
Vue.component('miniBasket', miniBasket);

// Оформление заказа
import checkout from './components/cart/checkout';
Vue.component('checkout', checkout);

// Список выбора типа оплаты
import paymentList from './components/cart/checkout/paymentList';
Vue.component('paymentList', paymentList);

import alertCityStop from "./components/AlertCityStop.vue";
Vue.component('AlertCityStop', alertCityStop);

import PaymentInfo from "./components/static-pages/payment-info/PaymentInfo.vue";
Vue.component('PaymentInfo', PaymentInfo);

import UdsInformation from "./components/static-pages/udsInformation.vue";
Vue.component('UdsInformation', UdsInformation);

// Глобальное окно ошибки
import DialogError from "./components/modals/DialogError.vue";
Vue.component('dialog-error', DialogError);

// Глобальное окно успеха
import DialogSuccess from "./components/modals/DialogSuccess.vue";
Vue.component('dialog-success', DialogSuccess);

import DialogInfo from "./components/modals/DialogInfo.vue";
Vue.component('dialog-info', DialogInfo);

// Глобальное окно подтверждения
import DialogConfirm from "./components/modals/DialogConfirm.vue";
Vue.component('dialog-confirm', DialogConfirm);

import DialogCookie from "./components/modals/DialogCookie.vue";
Vue.component('dialog-cookie', DialogCookie);

import payByQr from "./components/cart/pay/PayByQr.vue";
Vue.component('pay-by-qr', payByQr)
