import { init, setUser, replayIntegration, browserTracingIntegration } from '@sentry/vue';
import { getCsrfToken } from './helpers';

console.log(process.env.MIX_SENTRY_DSN);
init({
    dsn: process.env.MIX_SENTRY_DSN,

    integrations: [
        browserTracingIntegration(),

        replayIntegration({
            maskAllText: false,
            blockAllMedia: false,
            maskAllInputs: false,
            networkDetailAllowUrls: [
                window.location.origin,
            ],
        }),
    ],

    tracesSampleRate: 1.0,
    tracePropagationTargets: [ window.location.origin, /^\// ],

    replaysSessionSampleRate: 1,
    replaysOnErrorSampleRate: 1,
});

setUser({
    id: getCsrfToken(),
    ip_address: '{{auto}}',
});
