<template>
    <BaseDialog v-bind="$attrs" :title="showTitle" @close="close">
        <template v-slot:text>
            <p v-html="text"></p>
        </template>
        <template v-slot:buttons>
            <div class="modal-base__button" @click="close">
                ОК
            </div>
        </template>
    </BaseDialog>
</template>

<script>
import BaseDialog from "./BaseDialog.vue";

export default {
    name: 'dialog-info',
    components: { BaseDialog },
    props: {
        message: {
            type: String,
            default: ''
        },
        title: {
            type: String,
            default: ''
        }
    },
    computed: {
        text() {
            return this.message ? this.message : 'Это важная информация. Обратите внимание!';
        },
        showTitle() {
            return this.title ? this.title : `Информация на ${window.location.hostname}`;
        }
    },
    methods: {
        close() {
            this.$emit('close');
        }
    },
}
</script>

<style scoped>
.modal-base__button {
    background-color: #F1F3F5;
    color: black;
}

.modal-base__button:hover {
    background-color: #dbdbdc;
}
</style>
