<template>
    <div>
        <template v-if="isShowMobile">
            <div style="text-align: center">
                <h1 class="page-title">Оплата СБП</h1>
            </div>
            <div class="text-center width_370 margin_0_auto">
                <div class="card__total card__total_mobile">
                    <span>Итого: {{ $root.createdOrder.payload.payment.total }} ₽</span>
                </div>
                <div class="card__timer card__timer_mobile">
                    <span>Завершите платёж в течение <span class="card__timer_time">{{ expired_string }}</span></span>
                </div>
                <div class="card card_mobile mt-3">
                    <div class="card__description card__description_mobile">
                        <span>Для оплаты отсканируйте QR-код в мобильном приложении банка или штатной камерой телефона</span>
                    </div>
                    <div class="card__qr card__qr_mobile mt-3 mb-3">
                        <img :src="qr" alt="qr" :class="classForQr">
                    </div>
                    <div class="card__questions">
                        <span>Если возникли дополнительные вопросы, то посетите наш раздел <a class="card__questions_a" href="/payinfo">Оплата</a></span>
                    </div>
                </div>
                <a target="_blank" :href="payload" class="btn card__button card__button_red width_370 mt-3">Перейти на сайт СБП</a>
            </div>
        </template>
        <template v-else>
            <div class="margin_0_auto w-max">
                <h1 class="page-title">Оплата СБП</h1>
                <div class="card">
                    <div class="card__left_column">
                        <div>
                            <div class="card__total mb-2">
                                <span>Итого: {{ $root.createdOrder.payload.payment.total }} ₽</span>
                            </div>
                            <div class="card__timer mb-5">
                                <span>Завершите платёж в течение <b>{{ expired_string }}</b></span>
                            </div>
                        </div>
                        <div class="margin_x_20">
                            <div class="card__description mb-2">
                                <span>Для оплаты отсканируйте QR-код в мобильном приложении банка или штатной камерой телефона</span>
                            </div>
                            <div class="card__questions mb-3">
                                <span>Если возникли дополнительные вопросы, то посетите наш раздел <a class="card__questions_a" href="/payinfo">Оплата</a></span>
                            </div>
                            <div>
                                <div class="btn card__button card__button_green" @click="backToBasket">Вернуться к оформлению</div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="card__qr">
                            <img :src="qr" alt="qr" :class="classForQr">
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </div>

</template>

<script>
export default {
    name: 'pay-by-qr',
    data() {
        return {
            statusTimer: null,
            expired_string: null,
            updateTimerId: null,
            pending: false
        }
    },
    methods: {
        checkOrderPaymentStatus() {
            if (!this.order_id || this.pending) return

            this.pending = true
            axios.post('/basket/check-order-payment-status', {order_id: this.order_id})
                .then(({data}) => {
                    this.pending = false
                    if (data.is_paid === true) {
                        this.$root.openModal('order-created')
                        clearInterval(this.updateTimerId)
                        clearInterval(this.statusTimer)
                    }
                }).catch(console.error)
        },
        backToBasket() {
            window.location.replace('/basket')
        },
        updateTimer() {
            const now = moment()
            if (now > this.expired_at) {
                window.location.replace('/basket')
            }

            const duration = moment.duration(this.expired_at.diff(now))
            this.expired_string = duration.minutes() + ':' + (duration.seconds() < 10 ? '0' + duration.seconds() : duration.seconds())
        }
    },
    computed: {
        isShowMobile() {
            return this.$root.window.width <= 1060
        },
        order_id() {
            return this.$root.online_payment.orderId
        },
        payload() {
            return this.$root.online_payment?.sbp.payload ?? null
        },
        qr() {
            return this.$root.online_payment?.sbp.qr ?? null
        },
        created_at() {
            return moment(this.$root.online_payment?.created_at) ?? null
        },
        expired_at() {
            return moment(this.$root.online_payment?.expired_at) ?? null
        },
        classForQr() {
            return this.isShowMobile ? 'qr__size_mobile' : 'qr__size_desktop'
        }
    },
    beforeMount() {
        this.$root.createdOrder = this.$root.cacheStorage('get', 'createdOrder')
    },
    mounted() {
        this.statusTimer = setInterval(() => this.checkOrderPaymentStatus(), 1500);
        this.updateTimer()
        this.updateTimerId = setInterval(() => this.updateTimer(), 1000)
    }
}
</script>

<style lang="scss" scoped>
$qr-height: 290px;
$qr-width: 290px;
$qr-height-mobile: 200px;
$qr-width-mobile: 200px;
$text-grey: #8A8A8A;
$text-dark-grey: #333;
$button-red: #ED1F24;
$button-green: #00BF53;

.max_width_content {
    margin: 0 auto;
    max-width: 1030px;
}
.card {
    max-width: 950px;
    background: #fff;
    border-radius: 20px;
    justify-content: space-around;
    padding: 45px 40px;
    font-family: Roboto, sans-serif;
    margin: 0 auto;

    &_mobile {
        flex-direction: column;
        text-align: center;
        width: 280px;
        padding: 20px 25px;
    }
    &__total {
        font-size: 26px;
        font-weight: 500;
        line-height: 20px;
    }
    &__timer {
        color: $text-grey;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        &_time {
            font-weight: 500;
            color: $text-dark-grey;
        }
    }
    &__description {
        color: $text-dark-grey;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        &_mobile {
            color: $text-grey;
            font-size: 14px;
            line-height: 20px;
        }
    }
    &__questions {
        color: $text-grey;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        &_a {
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            color: $button-green;
            text-decoration: none;
            &:link, &:visited {
                text-decoration: none;
            }
        }
    }
    &__left_column {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    &__button {
        box-sizing: border-box;
        border-radius: 60px !important;
        &_red {
            background-color: $button-red;
        }
        &_green {
            background-color: $button-green;
        }
    }
    &__qr {
        margin: 0 auto;
        width: $qr-width;
        height: $qr-height;
        &_mobile {
            width: $qr-width-mobile;
            height: $qr-height-mobile;
        }
    }
}
.card__total_mobile, .card__timer_mobile {
    text-align: center;
}
.qr__size {
    &_desktop {
        height: $qr-height;
        width: $qr-width;
    }
    &_mobile {
        height: $qr-height-mobile;
        width: $qr-width-mobile;
    }
}
.text-center {
    text-align: center;
}
.width_370 {
    width: 330px;
}
.w-100 {
    width: 100%;
}
.w-max {
    width: max-content;
}
.margin_0_auto {
    margin: 0 auto;
}
.margin_x_20 {
    margin: 0 20px;
}
.flex-column {
    flex-direction: column;
}
</style>
