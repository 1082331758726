export default {
    methods: {
        setInitialOrderType() {
            if (!this.$root.city.delivery) {
                let orderType = 'takeaway';
                this.$root.cacheStorage('set', 'orderType', orderType);
                this.$root.orderType = orderType;
                this.$root.modal.open.data.orderType = orderType;
            }
        },
    }
}
