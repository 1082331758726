<template>
    <div class="modal-cookie" :style="{'display': visible ? 'block' : 'none'}">
        <div class="modal-cookie__content">
            <div class="modal-cookie__close" @click="close">
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="32" height="32" rx="16" fill="white"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M22.7071 10.7071C23.0976 10.3166 23.0976 9.68342 22.7071 9.29289C22.3166 8.90237 21.6834 8.90237 21.2929 9.29289L16 14.5858L10.7071 9.29289C10.3166 8.90237 9.68342 8.90237 9.29289 9.29289C8.90237 9.68342 8.90237 10.3166 9.29289 10.7071L14.5858 16L9.29289 21.2929C8.90237 21.6834 8.90237 22.3166 9.29289 22.7071C9.68342 23.0976 10.3166 23.0976 10.7071 22.7071L16 17.4142L21.2929 22.7071C21.6834 23.0976 22.3166 23.0976 22.7071 22.7071C23.0976 22.3166 23.0976 21.6834 22.7071 21.2929L17.4142 16L22.7071 10.7071Z" fill="#8A8A8A"/>
                </svg>
            </div>
            <div class="modal-cookie__text">
                Мы используем cookies, чтобы наш сайт стал удобнее для Вас. Используя наш сайт, вы принимаете
                <a href="/terms" style="color: #8A8A8A">условия обработки персональных данных</a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        visible: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        close() {
            this.$emit('close');
        }
    }
}
</script>


<style scoped lang="scss">
.modal-cookie {
    position: fixed;
    z-index: 20001;
    right: 60px;
    bottom: 0;
    overflow: hidden;
}

.modal-cookie__content {
    background-color: #fefefe;
    color: #333333;
    padding: 22px;
    border: none;
    position: relative;
    border-radius: 20px 20px 0 0;
    width: 326px;
}

.modal-cookie__close {
    color: #aaa;
    font-size: 30px;
    position: absolute;
    right: 5px;
    top: 5px;
}

.modal-cookie__close,
.modal-cookie__close svg,
.modal-cookie__close rect,
.modal-cookie__close path {
    cursor: pointer;
}

.modal-cookie__text {
    margin-top: 24px;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
}
</style>
