<template>
    <div v-show="isShow" class="modal" :class="[`modal--${$options.name}`]">
        <div class="loader" v-show="processing"></div>

        <template v-if="!submitted">
            <div class="modal__title">Написать директору</div>

            <form @submit.prevent="onSubmit" ref="form">
                <label>
                    <input v-maska data-maska="+7 (###) ###-##-##" type="tel" name="phone" ref="phone" required>
                    <span>Ваш телефон</span>
                </label>

                <label>
                    <input type="text" name="name" required>
                    <span>Ваше имя</span>
                </label>

                <label>
                    <textarea name="message" rows="4"></textarea>
                    <span>Ваше сообщение</span>
                </label>

                <label class="privacy-policy">
                    <input type="checkbox" name="accept" required>
                    <span>Согласен с <a :href="privacyPolicyUrl" class="link link--black link--underline" target="_blank">политикой конфиденциальности</a></span>
                </label>

                <button type="submit" class="btn btn--red">Отправить</button>
            </form>
        </template>

        <template v-else>
            <div class="modal__title">Сообщение успешно отправлено</div>
        </template>

        <div class="modal__close btn btn--remove" @click="close"></div>
    </div>
</template>

<script>
import { vMaska } from "maska";
export default {
    name: 'write-boss',
    props: ['privacyPolicyUrl'],

    directives: {
        maska: vMaska,
    },

    data: () => ({
        processing: false,
        submitted: false
    }),

    methods: {
        open() {
            this.$root.openModal(this.$options.name);
        },

        close() {
            this.$root.closeModal(this.$options.name);
        },

        onSubmit() {
            this.processing = true;

            $.ajax({
                url: '/sendForm/writeBoss',
                type: 'post',
                headers: {'x-csrf-token': $('meta[name="csrf-token"]').attr('content')},
                data: $(this.$refs.form).serialize(),
                success: (response) => {
                    if (response.status !== 'success') return alert('Проблема отправки письма');
                    this.submitted = true;
                },
                complete: () => {
                    this.processing = false;
                }
            });
        }
    },

    computed: {
        isShow() {
            return this.$root.modal.open.name === this.$options.name;
        }
    },
}
</script>
