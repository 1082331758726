<template>
    <div v-if="isShow" class="modal" :class="[`modal--${$options.name}`]">
        <product-view :product="product"></product-view>
    </div>
</template>

<script>
    export default {
        name: 'product',

        methods: {
            open() {
                this.$root.openModal(this.$options.name);
            },
        },

        computed: {
            isShow() {
                return this.$root.modal.open.name === this.$options.name;
            },

            product() {
                return this.$root.modal.open.data.product;
            }
        }
    }
</script>
