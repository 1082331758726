<template>
    <div class="modal-mobile" :class="{'modal-mobile__disabled': $root.loadingUds}">
        <div class="modal-content">
            <div class="payment_method__order-total">
                <div style="width: 60%">
                    <div class="payment_method__order-delivery" v-show="isDelivery">Доставка - {{ $root.deliveryInfo.price === undefined ? '(адрес не задан)' : $root.deliveryInfo.price + ' ₽' }}</div>
                    <div class="payment_method__order-delivery" v-show="!isDelivery">Доставка - бесплатно</div>
                    <template v-if="$root.deliveryInfo.price > 0 && $root.deliveryInfo.sumToFreeDiff > 0">
                        <span class="checkout__delivery-price--hint">До бесплатной доставки осталось <span class="currency">{{ $root.deliveryInfo.sumToFreeDiff }}</span></span>
                    </template>
                </div>
                <div class="payment_method__order-amount">
                    <p>Итого:</p>
                    <span>{{ $root.basket.cart.price.total - ($root.uds.applied ? $root.uds.receipt.points : 0) }} ₽</span>
                </div>
            </div>

            <div class="modal-mobile__buttons">
                <button class="payment_method__order-create" @click="createOrder">
                    Заказать
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    computed: {
        isDelivery() {
            return this.$root.orderType === 'delivery';
        }
    },
    methods: {
        createOrder() {
            this.$parent.createOrder(true);
        }
    }
}
</script>

<style lang="scss">
.modal-mobile__disabled {
    display: none;
}
</style>
