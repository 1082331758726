export default {
    methods: {
        selectDateFromPicker(selectDate) {
            //Если выбранная дата равняется с текущей, надо проверить не завершился ли сегодня прием заказов
            let curD = this.minDatePicker();
            let isToday = this.isCurrentDate(this.getCurTime(), selectDate);

            this.orderTime.date = this.datetimeToYmd(selectDate);

            //Пересчитываем время для заказа
            let time = this.timeListGenerate(curD, isToday);
            this.orderTime.time = time[0];
            this.timeList = time;
        },
        datetimeToYmd(date) {
            let day = ("0" + date.getDate()).slice(-2);
            let month = ("0" + (date.getMonth() + 1)).slice(-2);

            return date.getFullYear() + "-" + month + "-" + day;
        },
        minDatePicker() {
            let curD = this.getCurTime();
            curD.setMinutes(curD.getMinutes()+this.$root.basket.requirements.cookingMin);

            if (this.$root.orderType === 'delivery') {
                curD.setMinutes(curD.getMinutes()+this.$root.basket.requirements.deliveryMin);
            }
            let getTime = this.orderAcceptanceTime(); //Время приема заказов
            let acceptanceOfOrdersBefore = new Date(curD);  //Высчитываем до какого времени прием заказов
            acceptanceOfOrdersBefore.setHours(getTime.close.h);
            acceptanceOfOrdersBefore.setMinutes(getTime.close.m);

            //Если текущее время больше уже до которго принимают заказы, ставим +1 день
            if (curD > acceptanceOfOrdersBefore) {
                curD.setDate(curD.getDate() + 1);
            }

            return curD;
        },
        timeListGenerate(curD,isToday) {
            let time = [];
            let getTime = this.orderAcceptanceTime(); //Время приема заказов

            let open = getTime.open;
            let close = getTime.close;
            let hour = getTime.hour;
            let minute = getTime.minute;

            if (hour >= open.h) {
                open = {h: hour, m: minute};
            }

            for (var h = open.h; h <= close.h; h++) {
                for (var m = 0; m < 60; m++) {
                    if (isToday) {
                        //Только если это текущий день, не даем выбрать клиенту время раньше, чем сейчас
                        if (curD.getHours() > h) {
                            continue;
                        }
                        if (curD.getHours() === h) {
                            if (curD.getMinutes() > m) {
                                continue;
                            }
                        }
                    }

                    if (m === 0 || m === 15 || m === 30 || m === 45) {
                        //проверка вхожденяи даты в рабочие часы
                        if (h >= open.h && h <= close.h) {
                            if (h === open.h) {
                                if (m < open.m) continue;
                            }
                            if (h === close.h) {
                                if (m > close.m) continue;
                            }
                            time.push((h < 10 ? '0' + h : h) + ':' + (m < 10 ? '0' + m : m));
                        }
                    }
                }
            }

            this.orderTime.time = time[0];
            this.timeList = time;

            return time;
        },
        orderAcceptanceTime() {
            //Текущее время
            let curD = this.getCurTime();
            let open = this.city.reception_orders.openTime;
            let close = this.city.reception_orders.closeTime;

            open = open.split(":"); //Время начало работы
            open = new Date(curD.getFullYear(), curD.getMonth(), curD.getDate(), parseInt(open[0]), parseInt(open[1]), 0, 0);
            open.setMinutes(open.getMinutes());
            open = {h: open.getHours(), m: open.getMinutes()};

            close = close.split(":"); //Время завершения работы
            close = new Date(curD.getFullYear(), curD.getMonth(), curD.getDate(), parseInt(close[0]), parseInt(close[1]), 0, 0);
            close = {h: close.getHours(), m: close.getMinutes()};

            //Если текущее время больше, чем время начала работы, то нужно скорректировать начальну дату
            var hour = curD.getHours();
            var minute = curD.getMinutes();

            let time = {
                open: open,
                close: close,
                curD: {hour: hour, minute: minute, date: curD}
            };

            //Ближайшее время
            if (this.selectTime === 'soon') {
                let result = null;
                if (time.curD.hour === time.close.h) {
                    if (time.curD.minute > time.close.m) result = 1;
                } else if (time.curD.hour === time.open.h) {
                    if (time.curD.minute < time.open.m) result = 2;
                } else if (time.curD.hour > time.close.h) {
                    result = 1;
                } else if (time.curD.hour < time.open.h) {
                    result = 2;
                }

                if (result === 1) {
                    this.errorsMessage.soon = this.$root.orderType === 'delivery'
                        ? 'Доставка уже не работает.'
                        : 'Торговая точка уже закрыта.';

                    this.errorsMessage.soon += ' Заказ оформить можно завтра с '
                        + ((time.open.h < 9) ? '0' + time.open.h : time.open.h)
                        + ':' + ((time.open.m < 9) ? '0' + time.open.m : time.open.m);

                } else if (result === 2) {
                    this.errorsMessage.soon = this.$root.orderType === 'delivery'
                        ? 'Доставка уже не работает.'
                        : 'Торговая точка еще закрыта.';

                    this.errorsMessage.soon += ' ' + 'Заказ на ближайшее время можно будет оформить с' + ' '
                        + ((time.open.h < 9) ? '0' + time.open.h : time.open.h)
                        + ':' + ((time.open.m < 9) ? '0' + time.open.m : time.open.m);
                } else {
                    this.errorsMessage.soon = '';
                }
            }

            return time;
        },
        //попадает ли в промежуток времени ?
        checkTime(curTime, beg, end) {
            beg += ':00';
            end += ':00';
            var s = 60,
                d = ':',
                b = beg.split(d), b = b [0] * s * s + b [1] * s + +b [2],
                e = end.split(d), e = e [0] * s * s + e [1] * s + +e [2],
                t = curTime.getHours() * s * s + curTime.getMinutes() * s + curTime.getSeconds();

            return (t >= b && t <= e);
        },
        //Вычисляем текущее время
        getCurTime() {
            //Текущее смещение до UTC
            var d = new Date();
            var offset = -d.getTimezoneOffset();
            //Добавляем часовой пояс
            offset -= this.city.timeZoneOffsetInHours * 60;
            d = d.getTime();

            return new Date(d - (offset * 60 * 1000));
        },
        getParamDate() {
            //Расчитываем начальную и конечную дату приема заказа для календаря
            let minDate = this.getCurTime(); //Начальная дата
            let maxDate = new Date(); // конечная дата
            if (this.$root.orderType === 'delivery') {
                maxDate.setDate(maxDate.getDate() + 15); //Разрешаем оформлять заказ максимум на 15 деней вперед
            } else {
                maxDate.setMonth(maxDate.getMonth() + 1); //Разрешаем оформлять заказ максимум на 1 месяц вперед
            }
            return {maxDate: maxDate, minDate: minDate}
        },
        isCurrentDate(date1, date2) {
            return date1.getDate() + date1.getMonth() + date1.getFullYear() === date2.getDate() + date2.getMonth() + date2.getFullYear()
        },
    }
}
