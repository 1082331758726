<template>
    <div v-show="visible" class="modal modal--forgot-to-add-modal">
        <div class="modal__header">
            <span class="modal__title">Кажется, вы что-то забыли...</span>
        </div>
        <div class="modal__description">
            <span>С нашими соусами, васаби и имбирём гораздо вкуснее,
                не забудьте добавить их в корзину!</span>
        </div>
        <div class="modal__img">
            <img src="/images/soputka.png" alt="сопутствующие товары">
        </div>

        <div class="modal__buttons">
            <button class="modal__buttons--continue" @click="createWithoutSpices">Продолжить</button>
            <button class="modal__buttons--add" @click="goToSpices">Вернуться и добавить</button>
        </div>
        <div class="modal__close" @click="close">
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M13.7071 1.70711C14.0976 1.31658 14.0976 0.683418 13.7071 0.292893C13.3166 -0.0976311 12.6834 -0.0976311 12.2929 0.292893L7 5.58579L1.70711 0.292893C1.31658 -0.0976311 0.683418 -0.0976311 0.292893 0.292893C-0.0976311 0.683418 -0.0976311 1.31658 0.292893 1.70711L5.58579 7L0.292893 12.2929C-0.0976311 12.6834 -0.0976311 13.3166 0.292893 13.7071C0.683418 14.0976 1.31658 14.0976 1.70711 13.7071L7 8.41421L12.2929 13.7071C12.6834 14.0976 13.3166 14.0976 13.7071 13.7071C14.0976 13.3166 14.0976 12.6834 13.7071 12.2929L8.41421 7L13.7071 1.70711Z" fill="#8A8A8A"/>
            </svg>
        </div>
    </div>
</template>

<script>
export default {
    name: 'forgot-to-add-modal',
    props: {
        visible: Boolean
    },
    watch: {
        visible(val) {
            val ? document.body.classList.add('modal-open') : document.body.classList.remove('modal-open')
        }
    },
    methods: {
        goToSpices() {
            document.getElementById('product-slider')?.scrollIntoView({behavior: 'smooth', block: 'center'})
            this.close()
        },
        createWithoutSpices() {
            this.close()
            this.$emit('create-without-spices')
        },
        close() {
            this.$emit('close')
        },
    },
}
</script>

<style scoped lang="scss">
.modal {
    &--forgot-to-add-modal {
        border-radius: 20px;
        display: grid;
        grid-column-gap: 80px;
        padding: 32px 45px;
        width: 935px;
        justify-content: space-between;
        grid-template-areas:
            'img header'
            'img description'
            'img description'
            'img description'
            'img description'
            'img buttons';

        @media (max-width: 1100px) {
            gap: 30px;
            width: 400px;
            grid-template-areas: 'header' 'description' 'img' 'buttons';

            @media (max-width: 700px) {
                position: fixed;
                left: 0;
                top: auto;
                transform: none;
                bottom: 0;
                width: 100%;
                background-color: #FFFFFF;
                z-index: 10001;
                border-radius: 22px 22px 0 0;
                gap: 15px;
                padding: 22px 24px;
                grid-template-areas: 'header' 'description' 'img' 'buttons';
            }
        }
    }
    &__header {
        grid-area: header;
        padding-top: 36px;
    }
    &__description {
        grid-area: description;

        span {
            font-family: Roboto, sans-serif;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;

            @media (max-width: 700px) {
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
            }
        }
    }
    &__img {
        grid-area: img;
        margin: 0 auto;

        img {
            @media screen and (max-width: 700px) {
                width: 270px;
            }
        }
    }
    &__buttons {
        grid-area: buttons;
        display: flex;
        justify-content: space-between;
        height: 48px;

        &--add, &--continue {
            padding: 14px 31px;
            border-radius: 60px;
            cursor: pointer;
            font-size: 20px;
            font-weight: 500;
        }

        &--add {
            grid-area: add;
            color: white;
            background-color: #00BF53;
            border: none;
        }

        &--continue {
            grid-area: continue;
            color: black;
            background-color: #ffffff;
            border: 1px solid black;

            &:hover {
                background-color: #f1f1f1;
            }
        }

        @media (max-width: 1100px) {
            display: flex;
            flex-direction: column-reverse;
            gap: 15px;
            height: auto;
        }

        @media (max-width: 950px) {
            display: grid;
            grid-template-areas: "continue"
                "add";
            grid-template-columns: 1fr;

            &--continue {
                border: 1px solid #8A8A8A;
            }
        }
    }

    &__close {
        right: 26px;
        top: 26px;
        cursor: pointer;

        @media (max-width: 950px) {
            right: auto;
            left: 26px;
        }
    }
}

.modal__buttons .btn {
    padding: 14px 40px;
}

.button_without_spices {
    color: #333;
    text-align: center;
    font-family: Roboto,sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    border-color: #333;
}
</style>




