<template>
    <div class="card">
        <div class="card__left-column">
            <point>{{ point }}</point>
            <div class="card__left-line" v-if="showLine"></div>
        </div>
        <div class="card__right-column">
            <div class="card__heading margin-bottom_17" :class="{ 'card__heading_mobile': isMobile }">{{ heading }}</div>
            <div v-if="typeof description === 'string'" class="card__description margin-bottom_24" :class="{ 'card__description_mobile': isMobile }">
                {{ description }}
            </div>
            <div v-else>
                <ol type="1" class="margin-bottom_24" :class="{'ol_mobile': isMobile}">
                    <li v-for="(item, index) in description" :key="index">
                        <div class="card__description" :class="{ 'card__description_mobile': isMobile }">
                            {{ item }}
                        </div>
                    </li>
                </ol>
            </div>
            <div v-if="classImg" :class="classImg"></div>
            <div class="margin-bottom_17"></div>
        </div>
    </div>
</template>

<script>
import Point from './Point.vue'

export default {
    name: 'CardPaymentInfo',
    components: {Point},
    props: {
        point: Number,
        heading: String,
        description: String|Array,
        classImg: String,
        isMobile: Boolean,
        showLine: Boolean,
    },
}
</script>

<style scoped>
.ol_mobile {
    padding-left: 20px;
}
ol > li {
    font-size: 20px;
}
</style>
