<template>
    <div class="order-basket">
        <alert-city-stop v-if="isStopList"></alert-city-stop>
        <div class="loader" v-show="processing"></div>
        <div class="making_order" v-if="!isStopList">
            <div class="making_order__title--mobile">
                <a href="/">
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="32" height="32" rx="16" fill="white"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M22.7071 10.7071C23.0976 10.3166 23.0976 9.68342 22.7071 9.29289C22.3166 8.90237 21.6834 8.90237 21.2929 9.29289L16 14.5858L10.7071 9.29289C10.3166 8.90237 9.68342 8.90237 9.29289 9.29289C8.90237 9.68342 8.90237 10.3166 9.29289 10.7071L14.5858 16L9.29289 21.2929C8.90237 21.6834 8.90237 22.3166 9.29289 22.7071C9.68342 23.0976 10.3166 23.0976 10.7071 22.7071L16 17.4142L21.2929 22.7071C21.6834 23.0976 22.3166 23.0976 22.7071 22.7071C23.0976 22.3166 23.0976 21.6834 22.7071 21.2929L17.4142 16L22.7071 10.7071Z" fill="#8A8A8A"/>
                    </svg>
                </a>
                <div>Ваш заказ</div>
            </div>
            <div class="making_order__title">Оформление заказа</div>
            <basket-list :products="products" />
            <div class="making_order__paragraph"
                 v-if="isRelated">Не забудьте добавить</div>
            <product-slider
                :products="$root.basket.cart.related"
                v-if="isRelated"
            />
            <checkout ref="checkout" />
        </div>
        <div class="payment_method"
             :style="{'height': currentHeight}"
             v-if="!isStopList">
            <payment-method
                @getPayment="setPayment"
                @clearPromocode="$refs['checkout'].clearPromocode()"
                @clearUds="$refs['checkout'].clearUds()"
                @applyUds="$refs['checkout'].applyUds($event)"
                @applyPromocode="$refs['checkout'].applyPromocode($event)"
                @createOrder="$refs['checkout'].createOrder(true)"
            />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        products: {
            type: Array,
            default: []
        }
    },
    data() {
        return {
            payment: null,
            scrollY: null,
            processing: false,
            isStopList: false
        }
    },
    computed: {
        isRelated() {
            return Object.keys(this.$root.basket.cart.related).length > 0;
        },
        canOrderSubmit() {
            return this.$refs['checkout'].canOrderSubmit;
        },
        currentHeight() {
            let height = this.$root.orderType === 'delivery' ? 1395 : 1220;
            const countAll = this.$root.basket.cart.count;

            if (countAll > 1) {
                height = height + (160 * (countAll - 1));
            }

            if (this.$root.isChangeTotal) {
                height += 79;
            }

            return `${height}px`;
        }
    },
    methods: {
        setPayment(payment) {
            this.payment = payment;
        },
        checkStopBan() {
            this.isStopList = window.initialState.stop;
        }
    },
    created() {
        this.checkStopBan();
        this.$nextTick(() => {
            window.addEventListener('scroll', () => {
                this.scrollY = window.scrollY;
            });
        });
    }
}
</script>

<style scoped>
#app > .container {
    overflow: unset !important;
}
</style>
