<template>
    <div class="product-info__modifier" v-if="modifier.price !== null">
        <label>
            {{ modifier.name }}

            <span class="price">+{{ modifier.price }} ₽</span>
        </label>

        <span class="product-counter product-counter--transparent">
            <span class="product-counter__minus" :class="{ 'product-counter__minus--disabled': modifier.value <= modifier.min }" @click="modifier.value > modifier.min ? modifier.value-- : undefined"></span>
            <span class="product-counter__value">{{ modifier.value }}</span>
            <span class="product-counter__plus" :class="{ 'product-counter__plus--disabled': modifier.value >= modifier.max }" @click="modifier.value < modifier.max ? modifier.value++ : undefined"></span>
        </span>
    </div>
</template>

<script>
export default {
    name: 'modifier',
    props: ['modifier'],

    methods: {
        onInput(event, modifier) {
            if (this.modifier.min === 1 && this.modifier.max === 1) {
                modifier.value = +event.target.value === modifier.id ? 1 : 0;
            } else {

            }
        }
    },

    mounted() {
        this.$set(this.modifier, 'value', this.modifier.min);
        this.$set(this.modifier, 'checked', !!this.modifier.min);
    }
}
</script>
