<template>
    <div class="mini-basket">
        <a :href="isEmptyCart ? null : cartUrl" class="mini-basket__button" :style="{'border': show && !isEmptyCart ? '1px solid #00BF53' : '1px solid #D4D4D4'}" ref="cartButton">
            <template>
                <div class="mini-basket__icon" :style="{ filter: loading ? 'blur(1px)' : null }">
                    <span>{{ cart.countAll }}</span>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20.0721 22.6069H3.81885C3.08188 22.6069 2.46928 22.0388 2.414 21.3034L1.3976 7.74464C1.33618 6.92783 1.98257 6.23077 2.80245 6.23077H21.0886C21.9084 6.23077 22.5548 6.92783 22.4934 7.74464L21.477 21.3034C21.4217 22.0388 20.8091 22.6069 20.0721 22.6069Z" stroke="#00BF53" stroke-width="1.5" stroke-miterlimit="10"/>
                        <path d="M7.10938 6.2292C7.10938 3.55768 9.27423 1.39282 11.9457 1.39282C14.6173 1.39282 16.7837 3.55768 16.7837 6.2292" stroke="#00BF53" stroke-width="1.5" stroke-miterlimit="10"/>
                    </svg>
                </div>
            </template>
            <span>Корзина</span>
            <div class="mini-basket__count--mobile">
                {{ cart.countAll }}
            </div>
        </a>

        <div class="basket" v-show="show && !isEmptyCart" ref="basket">
            <div class="loader" v-show="loading"></div>

            <div class="basket-items">
                <div class="loader" v-show="loading"></div>

                <div v-for="product in products" class="basket-item">
                    <div class="basket-item__image-wrapper">
                        <img class="basket-item__image" :src="product.img.big" :alt="product.name" height="165">

                        <div class="product-label">
                            <div v-for="label in product.labels" :key="label" :class="`product-label__item product-label__item--${label}`"></div>
                        </div>
                    </div>

                    <div class="basket-item__name">{{ product.name }}</div>
                    <div class="basket-item__description">{{ product.desc }}</div>
                    <div class="basket-item__meta">{{ product.quantityInProduct }} шт / {{ product.weight.value }} {{ product.weight.unit }}</div>
                    <div class="basket-item__price-one currency">{{ product.price | numberFormat }}</div>
                    <product-counter :product="product"></product-counter>
                    <div class="basket-item__price currency currency--top">{{ product.price * product.count | numberFormat }}</div>
                    <div class="btn btn--remove btn--remove--type-2" @click="$eventBus.$emit('sendCart', { type: 'forget', productId: product.index })"></div>

                    <div class="basket-item__modifiers">
                        <ul>
                            <li v-for="modifier in getAllModifiers(product)">
                                <template v-if="modifier.value > 1">
                                    x{{ modifier.value }}
                                </template>

                                {{ modifier.name }}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <hr>
            <div class="basket-bottom">
                <div class="subtotal">Итого: <span class="currency currency--top">{{ subTotal }}</span></div>
                <a :href="cartUrl" class="btn btn--success">Оформить заказ</a>
            </div>
        </div>
    </div>
</template>

<script>
    import * as Sentry from '@sentry/browser';

    export default {
        props: ['pageName', 'cartUrl'],
        data() {
            return {
                show: false,
                count: 0,
                unavailableCount: 0,
                timerId: null,
                requirements: {
                    onlyToday: false
                }
            }
        },
        computed: {
            isEmptyCart() {
                return Object.keys(this.products).length === 0;
            },
            visible() {
                let cartPages = [
                    'cart.basket',
                    'cart.checkout',
                    'cart.gateway',
                    'pay.cart.gateway'
                ];

                return !cartPages.includes(this.pageName);
            },

            cart() {
                return this.$root.basket.cart;
            },
            subTotal() {
                return this.$root.basket.cart.price.total - (this.$root.uds.applied ? this.$root.uds.receipt.points : 0);
            },
            products() {
                return this.$root.cartProductsSorted;
            },
            loading() {
                return !this.$root.basket.loaded;
            }
        },
        methods: {
            statsMetrics(name) {
                if (this.isEmptyCart) {
                    return false;
                }

                this.$root.statsMetrics(name);
            },
            getAllModifiers(product) {
                return [...product.modifiers.filter((modifier) => modifier.value), ...product.groupModifiers.reduce((carry, groupModifiers) => {
                    return [...carry, ...groupModifiers.modifiers.filter((modifier) => modifier.value)];
                }, [])]
            },
            renderCart(cart = this.cart) {
                //Шлем обновления Всем продуктам, что кол-во изменилось
                this.count = cart.count;
                this.$root.basket.cart = cart;
                this.subTotal = cart.price.subtotal;
                this.$eventBus.$emit("renderCartProduct", cart.goods);
                this.$root.basket.requirements = this.requirements;
                window.localStorage.setItem('cartHash', cart.hash)
            },
            sendCart(data) {
                this.$root.basket.loaded = false;

                axios.post('/basket/' + data.type, {
                    productId: data.productId,
                    productIndex: data?.productIndex,
                    referer: this.pageName,
                    count: data.type === 'add' && data.count ? data.count : null,
                    changeShop: this.$root.orderType === 'takeaway' ? this.$root.shop.id : null,
                    groupModifiers: data?.groupModifiers || [],
                    modifiers: data?.modifiers || [],
                })
                    .then((response) => {
                        this.$root.basket = response.data;
                        this.checkCart(this.cart);

                        if (data.type === 'add') this.$eventBus.$emit('add-to-cart');
                        window.localStorage.setItem('cartHash', response.data.cart.hash);
                    })
                    .catch((error) => {
                        if (error.message === 'canceled') {
                            return alert('Упс... Кажется у вас отключены cookie! Мы не сможем гарантировать работу сайта, пока вы их не включите :(')
                        }
                    })
                    .then(() => {
                        this.$root.basket.loaded = true;
                    });
            },
            addUnavailableGoods(cart) {
                if (!Object.keys(cart.unavailableGoods).length) {
                    let previousCart = this.$root.cacheStorage('get', 'previousCart');
                    this.cart = this.checkCountGoods(previousCart, cart);
                    return;
                }

                axios.post('/basket/addList', {
                    referer: this.pageName,
                    changeOrderType: this.$root.orderType,
                    products: Object.values(cart.unavailableGoods),
                    changeShop: this.$root.orderType == 'takeaway'
                        ? this.$root.shop.id
                        : null
                })
                .then((response) => {
                    this.cart = this.checkCountGoods(this.cart, response.data.cart);
                })
            },
            checkCountGoods(oldCart, newCart) {
                let goods = {};
                $.each(newCart.goods, function(index, value) {
                    if (oldCart.goods.hasOwnProperty(index) && oldCart.goods[index].count < value.count) {
                        goods[index] = oldCart.goods[index];
                    }
                });

                if (!goods) {
                    Sentry.captureException(new Error('Тут нужно было сделать getCart'));
                    // this.getCart();
                    return;
                }

                axios.post('/basket/addList', {
                    referer: this.pageName,
                    changeOrderType: this.$root.orderType,
                    products: Object.values(goods),
                    changeShop: this.$root.orderType == 'takeaway'
                        ? this.$root.shop.id
                        : null
                })
                .then((response) => {
                    this.cart = response.data.cart;
                })

                return this.cart ?? newCart;
            },
            savePreviousCart(cart = this.cart) {
                if (Object.keys(cart).length) {
                    let previousCart = this.$root.cacheStorage('get', 'previousCart');

                    if (previousCart && typeof previousCart.unavailableGoods !== "undefined") {
                        let unavailableGoods = Object.assign(previousCart.unavailableGoods, cart.unavailableGoods ?? {});
                        for (var key in unavailableGoods) {
                            if (!unavailableGoods[key]) {
                                delete unavailableGoods[key];
                            }
                        }
                        cart.unavailableGoods = unavailableGoods
                    }
                    this.$root.cacheStorage('set', 'previousCart', cart);
                }
            },
            checkCart(cart) {
                return;
                let unavailableGoods = {};
                let previousCart = this.$root.cacheStorage('get', 'previousCart');
                if (typeof this.cart.unavailableGoods !== "undefined" && Object.keys(this.cart.unavailableGoods).length) {
                    unavailableGoods = this.cart.unavailableGoods;
                } else if (previousCart && typeof previousCart.unavailableGoods !== "undefined") {
                    $.each(previousCart.unavailableGoods, function(index, value) {
                        if (!cart.goods.hasOwnProperty(index) && value) {
                            unavailableGoods[index] = value;
                        }
                    });

                    $.each(previousCart.goods, function(index, value) {
                        if (!cart.goods.hasOwnProperty(index) && value) {
                            unavailableGoods[index] = value;
                        }
                    });
                }

                if (!Object.keys(unavailableGoods).length) {
                    this.cart = cart;
                    this.$root.basket.loaded = true;
                    this.renderCart();

                    return;
                }

                for (var key in unavailableGoods) {
                    if (!unavailableGoods[key]) {
                        delete unavailableGoods[key];
                    }
                }

                axios.post('/basket/addList', {
                    referer: this.pageName,
                    changeOrderType: this.$root.orderType,
                    products: Object.values(unavailableGoods),
                    changeShop: this.$root.orderType == 'takeaway'
                        ? this.$root.shop.id
                        : null
                })
                .then((response) => {
                    this.cart = response.data.cart;
                    this.$root.basket.loaded = true;
                    this.renderCart();
                })
            },
            difference(previousCart, currentCart) {
                let differenceArray = currentCart.unavailableGoods;
                if (typeof currentCart.goods === "undefined" || !previousCart) {
                    return differenceArray;
                }

                if (typeof previousCart.unavailableGoods !== "undefined") {
                    differenceArray = previousCart.unavailableGoods;
                }

                $.each(previousCart.goods, function(index, value) {
                    if (!currentCart.goods.hasOwnProperty(index) && value) {
                        differenceArray[index] = value;
                    }
                });

                return differenceArray;
            },
            actualizeCart() {
                axios.post('/basket/actualize', {referer: this.pageName})
                    .then(response => {
                        this.renderCart(response.data.cart)
                    })
                    .catch(() => {})
            },
        },
        created() {
            //Добавления и удаления в корзине
            this.$eventBus.$on('sendCart', (data) => {
                this.sendCart(data)
            });

            // Вызов целей метрик из конфига
            for (const goalKey in window.initialState.metric.goals) {
                const goal = window.initialState.metric.goals[goalKey];

                this.$eventBus.$on(goalKey, () => {
                    if (typeof ym !== 'undefined' && goal.yandex) ym(window.yandexMetricId, 'reachGoal', goal.yandex);
                });
            }

            //Добавление по возможности недоступных товаров
            this.$eventBus.$on('addUnavailableGoods', (data) => {
                this.addUnavailableGoods(data)
            });

            //Для синхронизации корзины между вкладками
            this.$eventBus.$on('actualizeCart', () => {
                this.actualizeCart()
            })
        },
        mounted() {
            // if (this.pageName === 'cart.basket') return;

            $(this.$refs.cartButton).hover(() => {
                let close = true;

                this.show = true;
                $(this.$refs.basket).one('mouseenter', () => close = false);
                $(this.$refs.basket).one('mouseleave', () => this.show = false);

                $(this.$refs.cartButton).one('mouseleave', () => {
                    setTimeout(() => {
                        if (close) this.show = false;
                    }, 100);
                });
            });
        }
    }
</script>
