<template>
    <div class="headerYandexMap">
        <div class="loader" v-show="loading"></div>
        <div class="headerYandexMap__address">
            <div class="yandex-address__input"
                 v-show="$parent.isDelivery && $root.window.width > 549">
                <label>
                    <input
                        type="text"
                        ref="input"
                        v-model="value"
                        @input="getSuggestions()"
                        @focus="focus = true"
                        :style="{'borderRadius': suggestList.length > 0 && focus === true ? '20px 20px 0 0' : '60px'}"
                        @blur="inputBlur()"
                        :data-empty="`${!value}`"
                        :class="{
                                error: status === 'not_found',
                                isDelivered: status === 'delivery',
                            }"
                        required
                    >
                    <span :class="{placeholder_warning: warning}">{{ placeholder }}</span>
                </label>
                <div class="not-found" v-show="notFound === true && focus === true">Результаты не найдены</div>
                <ul ref="suggestList" class="suggest-list"
                    v-show="suggestList.length > 0 && focus === true">
                    <li
                        v-bind:key="index"
                        v-for="(item, index) in suggestList"
                        ref="suggestItem"
                        class="suggest-item"
                        :class="{ 'active' : activeItemIndex === index }"
                        @click="validateSuggest(index, true)"
                    >
                        <div class="suggest-subtitle">{{ item.subtitle }}</div>
                        <div class="suggest-title">{{ item.title }}</div>
                    </li>
                </ul>
            </div>
            <button class="headerYandexMap__check-list-shops" v-show="!$parent.isDelivery" @click="$emit('showListShop')">
                Посмотреть список суши-баров
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.2542 10L20 16.504L13.2542 23" stroke="#ED1F24" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </button>
        </div>
        <div class="headerYandexMap__button-select-address">
            <button v-show="$parent.isDelivery" @click="saveAddress">Выбрать</button>
        </div>
        <div class="headerYandexMap__order-type">
            <radio-slider v-model="$root.orderType" :inputs="orderTypeInputs" />
        </div>
        <div class="headerYandexMap__close">
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" @click="close">
                <rect width="32" height="32" rx="16" fill="white"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M22.7071 10.7071C23.0976 10.3166 23.0976 9.68342 22.7071 9.29289C22.3166 8.90237 21.6834 8.90237 21.2929 9.29289L16 14.5858L10.7071 9.29289C10.3166 8.90237 9.68342 8.90237 9.29289 9.29289C8.90237 9.68342 8.90237 10.3166 9.29289 10.7071L14.5858 16L9.29289 21.2929C8.90237 21.6834 8.90237 22.3166 9.29289 22.7071C9.68342 23.0976 10.3166 23.0976 10.7071 22.7071L16 17.4142L21.2929 22.7071C21.6834 23.0976 22.3166 23.0976 22.7071 22.7071C23.0976 22.3166 23.0976 21.6834 22.7071 21.2929L17.4142 16L22.7071 10.7071Z" fill="#8A8A8A"/>
            </svg>
        </div>
        <div class="delivery-mobile-address"
             v-show="$root.window.width <= 549 && $parent.isDelivery"
             :style="styleModal">
            <div class="delivery-mobile-address__content">
                <div class="delivery-mobile-address__input-search">
                    <div class="delivery-mobile-address__suggest-search">
                        <button class="delivery-mobile-address__clear-suggest-search"
                            v-show="suggestList.length > 0 && focus === true"
                            @click="clearModal">
                            <svg width="9" height="15" viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.74582 14L1 7.49598L7.74582 1" stroke="#8A8A8A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </button>
                        <div class="block-group__item">
                            <input type="text" class="block-group__input"
                                   @input="getSuggestions()"
                                   @focus="focus = true"
                                   v-model="value" required>
                            <label :class="{'block-group__label': true, 'placeholder_warning': warning}">
                                {{ placeholder }}
                            </label>
                            <button class="block-group__icon"
                                    v-show="value"
                                    @click="clearValue">
                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.7071 1.70711C14.0976 1.31658 14.0976 0.683417 13.7071 0.292893C13.3166 -0.0976311 12.6834 -0.0976311 12.2929 0.292893L7 5.58579L1.70711 0.292893C1.31658 -0.0976311 0.683417 -0.0976311 0.292893 0.292893C-0.0976311 0.683417 -0.0976311 1.31658 0.292893 1.70711L5.58579 7L0.292893 12.2929C-0.0976311 12.6834 -0.0976311 13.3166 0.292893 13.7071C0.683417 14.0976 1.31658 14.0976 1.70711 13.7071L7 8.41421L12.2929 13.7071C12.6834 14.0976 13.3166 14.0976 13.7071 13.7071C14.0976 13.3166 14.0976 12.6834 13.7071 12.2929L8.41421 7L13.7071 1.70711Z" fill="#8A8A8A"/>
                                </svg>
                            </button>
                        </div>
                    </div>
                    <ul class="delivery-mobile-address__suggest-list"
                        v-show="suggestList.length > 0 && focus === true">
                        <li v-for="(item, index) in suggestList"
                            @click="validateSuggest(index, true)">
                            <p>{{ item.title }}</p>
                            <span>{{ item.subtitle }}</span>
                        </li>
                    </ul>
                </div>
                <button class="delivery-mobile-address__button-search"
                        v-show="!isSuggest"
                        @click="saveAddress()">Выбрать</button>
            </div>
        </div>
    </div>
</template>

<script>
import radioSlider from "../../radio-slider.vue";
import { checkAddressForDelivery } from "../../../axios/axios_geocoder";

export default {
    components: { radioSlider },
    data() {
        return {
            suggestList: [],        // Массив поисковых предложений
            activeItemIndex: false, // Индекс активного элемента
            disabled: false,        // Состояния поля ввода
            notFound: false,        // Флаг отсутствия поисковых подсказок
            focus: false,           // Флаг фокусировки на поле ввода
            loading: false,         // Отправка запроса в Geocoder
            status: '',
            value: '',
            orderTypes: [
                {
                    name: 'Доставка',
                    slug: 'delivery'
                },
                {
                    name: 'Самовывоз',
                    slug: 'takeaway'
                }
            ],
        }
    },
    props: {
        // Задержка ввода (мс)
        inputDelay: {
            type: Number,
            default: 0
        },
        // Координаты углов прямоугольника приоритета поиска
        cityName: {
            type: String,
            required: true
        },
        // Координаты углов прямоугольника приоритета поиска
        bbox: {
            type: String,
            required: true
        },
        mapValue: {
            type: String,
            default: ''
        },
    },
    watch: {
        '$parent.isShow'(val) {
            if (this.$root.addr.formated?.length > 0 && this.$root.addr.house && this.$root.addr.street) {
                if (this.$parent.$refs['delivery_map'].map) {
                    this.$parent.$refs['delivery_map'].getAddress([this.$root.addr.coordinates.latitude, this.$root.addr.coordinates.longitude]);
                }
            }
        },
        suggestList: function () {
            this.activeItemIndex = false;

            if (this.value.length === 0 && this.suggestList.length === 0) {
                this.notFound = false;
            }
        },
        mapValue: function (value) {
            if (value.length > 0) {
                this.value = value;
            }
        },
        '$parent.isDelivery'(val) {
            if (this.$parent.isShow) {
                if (val) {
                    this.getUserLocation()
                }
            }
        },
    },
    computed: {
        orderTypeInputs() {
            return this.orderTypes.map((orderType) => ({
                label: orderType.name,
                value: orderType.slug
            }));
        },
        isSuggest() {
            return this.suggestList.length > 0 && this.focus === true;
        },
        styleModal() {
            if (this.isSuggest) {
                return {
                    'height': '100%',
                    'backgroundColor': '#EBEBEB'
                };
            }

            return {'height': 'unset', 'backgroundColor': 'white'};
        },
        placeholder() {
            return this.warning ? 'Доставка по выбранному адресу невозможна' : 'Адрес *'
        },
        warning() {
            return this.$parent.outsideArea || this.status === 'not_found'
        }
    },
    methods: {
        checkAddressForDelivery,
        clearModal() {
            this.suggestList = [];
            this.focus = false;
        },
        clearValue() {
            this.value = '';

            let map = this.$parent.$refs['delivery_map'].map;

            map.geoObjects.each((geoObject) => {
                if (geoObject.geometry.getType() === 'Point') map.geoObjects.remove(geoObject);
            });
        },
        close() {
            this.value = '';

            if (this.$root.orderType === 'delivery' && (!this.$root.addr.house || !this.$root.addr.street)) {
                let map = this.$parent.$refs['delivery_map'].map;

                map.geoObjects.each((geoObject) => {
                    if (geoObject.geometry.getType() === 'Point') map.geoObjects.remove(geoObject);
                });
            }

            this.$emit('close');
        },
        saveAddress() {
            if (this.focus && this.suggestList.length > 0) {
                this.validateSuggest(0, true);
            }
            if (this.warning) {
                this.$root.clearDataOfAddress()
                this.$emit('close');
                return
            }

            this.$root.addr.formated = this.value;

            let address = this.$root.addr.formated;
            this.$root.addr.status = 'saving';
            this.getCoordinatesTheCustomerAddress(address);

            this.$emit('close');
        },
        getUserLocation() {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(position => {
                    let coords = [ position.coords.latitude, position.coords.longitude ];
                    this.$parent.$refs['delivery_map'].getAddress(coords);
                    this.$parent.$refs['delivery_map'].navigateTo(position.coords);
                });
            }
        },
        getSuggestions() {
            this.notFound = false;

            if (this.value.length < 2) {
                this.suggestList = [];
                this.activeItemIndex = null;
                return;
            }

            clearTimeout(this.timer);

            let data = {
                bases: 'geo,biz',
                bbox: this.bbox,
                countries: 'ru',
                fullpath: 1,
                highlight: 0,
                lang: 'ru_RU',
                local_only: 0,
                results: 7,
                text: this.value,
                type: 'addr',
                v: 9
            };

            if (this.bbox === '') {
                data.text = this.cityName + ', ' + data.text;
            } else {
                data.bbox = this.bbox;
            }

            this.timer = setTimeout(() => {
                $.ajax({
                    url: 'https://suggest-maps.yandex.ru/suggest-geo',
                    data: data,
                    dataType: "jsonp",
                })
                    .done((data) => {
                        this.suggestList = [];

                        let resultsCount = 0;
                        data.results.forEach((element, index) => {
                            if (element.tags.includes('entrance')) return;

                            if (element.personalization_info.type === 'chain' ||
                                element.personalization_info.type === 'fake_chain' ||
                                element.personalization_info.type === 'rubric' ||
                                element.type === 'query' ||
                                typeof element.subtitle === 'undefined'
                            ) {
                                return;
                            }

                            this.suggestList.push({
                                index: index,
                                title: element.title.text,
                                subtitle: ((element.type === 'business') ? element.subtitle.text.replace(' · ', ', ') : element.subtitle.text),
                                geoQuery: element.text,
                                type: element.type
                            });

                            resultsCount++;
                        });

                        if (resultsCount === 0) {
                            this.notFound = true;
                        }
                    });
            }, this.inputDelay);
        },
        validateSuggest(value, byIndex) {
            if (byIndex === true) {
                if (this.suggestList[value].type === 'business') {
                    this.value = this.suggestList[value].subtitle;
                } else {
                    this.value = this.suggestList[value].title;
                }
            }

            let geocode = '';

            if (byIndex === true && this.suggestList[value].type === 'business') {
                geocode = this.suggestList[value].subtitle.split(', ');
                geocode.shift();
                geocode = geocode.join(', ');
            } else if (byIndex === true && this.suggestList[value].type === 'toponym') {
                geocode = this.suggestList[value].geoQuery;
            } else if (byIndex === false) {
                geocode = this.value;
            }

            this.getCoordinatesTheCustomerAddress(geocode);

            this.suggestList = [];
        },
        validateAddressButton(event) {
            if (this.value.length < 1) return alert('Укажите адрес доставки');
            this.getCoordinatesTheCustomerAddress(this.value, true);
        },
        getCoordinatesTheCustomerAddress(address, close = false) {
            this.loading = true;
            this.$parent.outsideArea = false
            this.status = '';

            this.checkAddressForDelivery(address, 'delivery')
                .then((response) => {
                    if (response.data.found && response.data.kind !== 'house') {
                        this.value = response.data.formated + ', ';
                        this.loading = false;
                        this.status = '';

                        setTimeout(() => {
                            this.$refs.input.focus();
                            this.getSuggestions();
                        }, 100)

                        return true;
                    }

                    const coords = response.data.coordinates
                    const polygon = this.$parent.$refs['delivery_map'].getPolygonDataByCoords([coords.latitude, coords.longitude]);

                    if (response.data.found && polygon) this.value = response.data.formated;

                    if (response.data.found && response.data.delivered && polygon) {
                        this.status = 'delivery';
                    } else {
                        this.status = 'not_found';
                    }

                    if (response.data.zoneId) {
                        this.$parent.$refs['delivery_map'].getCustomDeliveryTime(response.data.zoneId)
                                .then(({data}) => this.$root.cookingTimeDelivery = data.minutes);
                    }

                    this.$root.unavailableClientAddress.show = !response.data.found || !response.data.delivered || !polygon;

                    this.$parent.searchDelivery(response.data);
                    this.$parent.$refs['delivery_map'].navigateTo(response.data.coordinates);
                })
                .catch((error) => {
                    console.error(error);
                    alert('Произошла ошибка на сервере. Мы уже исправляем ее, а пока можете обратиться на нашу горячую линию. Приносим свои извинения')
                })
                .then(() => {
                    this.loading = false;
                    if (this.status == 'delivery' && close) this.$parent.hideMap();
                });
        },
        inputBlur() {
            setTimeout(() => {
                this.focus = false;
            }, 500);
            if (this.warning)
                return
            this.$root.addr.formated = this.value;
        },
        openModal() {
            if (this.$parent.openModalOnArraw) {
                this.$root.openModal(this.$parent.openModalOnArraw, { orderType: 'delivery' });
            }
        },
    },
    created() {
        // Не реактивные данные
        this.timer = null; // Таймер задержки ввода

        //Получаем адрес из кеша
        this.status = '';
        if (this.$root.addr.formated?.length > 0 && this.$root.addr.house && this.$root.addr.street) {
            this.value = this.$root.addr.formated;
        }
    }
}
</script>

<style>
.placeholder_warning {
    color: red !important;
}
</style>
