/**
 * Возвращает куки с указанным name
 * @param {string} name
 * @return {string|null}
 */
function getCookie(name) {
    let matches = document.cookie.match(new RegExp(
        `(?:^|; )${name.replace(/([.$?*|{}()\[\]\\\/+^])/g, '\\$1')}=([^;]*)`
    ));

    return matches ? decodeURIComponent(matches[1]) : null;
}

/** Проверка доступности cookie */
export const cookiesIsEnabled = () => {
    if (window._cookiesIsEnabled === undefined) {
        try {
            document.cookie = 'testCookie';
            window._cookiesIsEnabled = document.cookie.indexOf('testCookie') !== -1;
        } catch (error) {
            window._cookiesIsEnabled = false;
        }
    }

    return window._cookiesIsEnabled;
};

/**
 * Получить csrf-token
 * прокидывает через window._csrfToken, тем самым "кэшируя" его глобально до перезагрузки страницы
 * @return string|null
 */
export function getCsrfToken() {
    return window._csrfToken = window._csrfToken ?? document.querySelector('meta[name="csrf-token"]')?.getAttribute('content') ?? null;
}

/**
 * Получить xsrf-token
 * @return string|null
 */
export function getXsrfToken() {
    return getCookie('XSRF-TOKEN');
}

// сначала выполняет, потом ждет
// export function debounce(f, ms) {
//     let isCooldown = false;
//
//     return function() {
//         if (isCooldown) return;
//
//         f.apply(this, arguments);
//         isCooldown = true;
//         setTimeout(() => isCooldown = false, ms);
//     };
// }

// сначала ждет, потом выполняет
export function debounce(f, delay){
    let lastTimeout = null;
    return function() {
        if(lastTimeout){
            clearTimeout(lastTimeout);
        }
        let args = Array.from(arguments);
        lastTimeout = setTimeout(function(){
            f.apply(null, args);
        }, delay);
    }
}
