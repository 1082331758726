<template>
    <BaseDialog v-bind="$attrs" :title="showTitle" @close="close">
        <template v-slot:text>
            {{ text }}
        </template>
        <template v-slot:buttons>
            <div class="modal-base__button close" @click="close">
                Отмена
            </div>
            <div class="modal-base__button confirm" @click="confirm">
                Подтвердить
            </div>
        </template>
    </BaseDialog>
</template>

<script>
import BaseDialog from "./BaseDialog.vue";

export default {
    name: 'dialog-confirm',
    components: { BaseDialog },
    props: {
        message: {
            type: String,
            default: ''
        },
        title: {
            type: String,
            default: ''
        }
    },
    computed: {
        text() {
            return this.message ? this.message : 'Вы действительно хотите выполнить данное действие?';
        },
        showTitle() {
            return this.title ? this.title : 'Обращаем ваше внимание';
        }
    },
    methods: {
        close() {
            this.$emit('close');
        },
        confirm() {
            this.$emit('confirm');
        }
    },
}
</script>

<style scoped>
.modal-base__button {
    font-size: 16px;
}

.modal-base__button.close {
    color: black;
    background-color: #F1F3F5
}

.modal-base__button.close:hover {
    background-color: rgb(173 173 173)
}

.modal-base__button.confirm:hover {
    background-color: rgba(0, 191, 83, 0.68);
}
</style>

