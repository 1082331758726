export default {
    methods: {
        getDeliveryZones(ymaps, myMap, withoutBalloon = false) {
            if (!myMap) {
                return;
            }

            var features = [];
            window.initialState.deliveryZone.forEach((item) => {

                let strBody = '---------------<br>';
                let strHeader = '';
                if (item.isFreeDelivery === false) {
                    strHeader += `Платная доставка - ${item.paidSum} ${window.initialState.currency.sign}`;
                    // strBody += `Доставка за <b>${item.minDelivery / 2}</b> - <b>${item.minDelivery}</b> мин.<br>Сумма заказа должна быть от <b>${item.minAmountOrderSum}</b> ${window.initialState.currency.sign}`;
                } else {
                    strHeader += `Бесплатная доставка от ${item.minAmountOrderSum} ${window.initialState.currency.sign}`;
                    // strBody += `Доставка <b>${item.minDelivery / 2}</b> - <b>${item.minDelivery}</b> мин.<br>Платная доставка <b>${item.paidSum}</b> ${window.initialState.currency.sign}, если заказ менее <b>${item.minAmountOrderSum}</b> ${window.initialState.currency.sign}`;
                }

                features.push({
                    "type": "Feature",
                    "id": 0,
                    "geometry": {
                        "type": "Polygon",
                        "coordinates": [item.coordinates]
                    },
                    "options": {
                        "fillColor": item.fillColor,
                        "fillOpacity": 0.3,
                        "strokeColor": '#ED1F24',
                        "strokeOpacity": 4
                    },
                    "properties": {
                        "name": item.name,
                        "zoneId": item.id,
                        "minDelivery": item.minDelivery,
                        "paidSum": item.paidSum,
                        "isFreeDelivery": item.isFreeDelivery,
                        "minAmountOrderSum": item.minAmountOrderSum,
                        "balloonContentHeader": withoutBalloon ? null : strHeader,
                        "balloonContent": withoutBalloon ? null : strBody
                    }
                });
            });

            ymaps.geoQuery({"type": "FeatureCollection", "features": features}).addToMap(myMap);
        }
    }
}
