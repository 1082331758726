<template>
    <BaseDialog v-bind="$attrs" :title="showTitle" @close="close">
        <template v-slot:text>
            {{ text }}
        </template>
        <template v-slot:buttons>
            <div class="modal-base__button error" @click="close">
                ОК
            </div>
        </template>
    </BaseDialog>
</template>

<script>
import BaseDialog from "./BaseDialog.vue";

export default {
    name: 'dialog-error',
    components: { BaseDialog },
    props: {
        message: {
            type: String,
            default: ''
        },
        title: {
            type: String,
            default: ''
        }
    },
    computed: {
        text() {
            return this.message ? this.message : 'Что-то пошло не так. Попробуйте еще раз';
        },
        showTitle() {
            return this.title ? this.title : `Ошибка на ${window.location.hostname}`;
        }
    },
    methods: {
        close() {
            this.$emit('close');
        }
    },
}
</script>

<style scoped>
.modal-base__button {
    background-color: #ED1F24;
}

.modal-base__button.error:hover {
    background-color: #b02225;
}
</style>
