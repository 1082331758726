<template>
    <div class="products-sections__wrapper">
        <section v-for="(category, categoryId) in productsByCategory" :id="categoryId">
            <div class="section__title">{{ category.label }}</div>

            <div class="products">
                <product-card v-for="product in category.products" :key="product.id" :product="product" />
            </div>
        </section>

        <dialog-error :visible="dialogError.visible"
                      :title="dialogError.title"
                      :message="dialogError.message"
                      @close="dialogError.visible = false" />
    </div>
</template>

<script>
export default {
    props: {
        productsByCategory: {
            type: Object
        }
    },
    data() {
        return {
            currentProducts: null,
            dialogError: {
                visible: false,
                title: '',
                message: ''
            },
        }
    },
    methods: {
        // Проверка стоп-листа при отображении каталога
        checkInStopList() {
            for (let categoryKey in this.productsByCategory) {
                let currentCategory = this.productsByCategory[categoryKey];
                let newProducts = [];

                for (let productKey in currentCategory.products) {
                    let product = currentCategory.products[productKey];

                    if (this.$root.shop.id && this.$root.orderType === 'takeaway') {
                        if (this.stopList.pickup.products[this.$root.shop.id]
                            && this.stopList.pickup.products[this.$root.shop.id].includes(product.id)) {
                            continue;
                        }
                    }

                    if (this.$root.addr.formated && this.$root.orderType === 'delivery' && this.$root.city.id) {
                        if (this.stopList.delivery.products[this.$root.city.id]
                            && this.stopList.delivery.products[this.$root.city.id].includes(product.id)) {
                            continue;
                        }
                    }

                    newProducts.push(product);
                }

                this.productsByCategory[categoryKey].products = newProducts;
            }
        }
    },
    watch: {
        stopList(stopList) {
            if (stopList) {
                this.checkInStopList(stopList);
            }
        }
    },
    computed: {
        stopList() {
            return this.$root.stopList;
        }
    }
}
</script>

<style scoped>

</style>
