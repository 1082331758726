<template>
    <div class="product-info__group-modifiers-item">
        <div class="group-title">{{ groupModifiers.name }}</div>
        <div v-if="groupModifiers.max > 1" class="group-subtitle">Выберите до {{ groupModifiers.max }}</div>

        <ul>
            <li v-for="modifier in groupModifiers.modifiers" v-if="modifier.price !== null">
                <label>
                    <input
                        v-model="modifier.checked"
                        @input="onInput($event, modifier)"
                        :type="groupModifiers.min === 1 && groupModifiers.max === 1 ? 'radio' : 'checkbox'"
                        :value="modifier.id"
                        :name="`modifierGroup_${groupModifiers.id}`"
                        :disabled="groupModifiers.min !== groupModifiers.max && groupTotalValue >= groupModifiers.max && !modifier.checked"
                    >

                    {{ modifier.name }}

                    <span class="price">+{{ modifier.price }} ₽</span>
                </label>

                <span v-if="groupModifiers.max > 1" :style="{ 'visibility': modifier.value ? 'visible' : 'hidden' }" class="product-counter product-counter--transparent">
                    <span class="product-counter__minus" :class="{ 'product-counter__minus--disabled': groupTotalValue <= groupModifiers.min || modifier.value === 1 || modifier.value <= groupModifiers.min }" @click="groupTotalValue > groupModifiers.min && modifier.value !== 1 && modifier.value > groupModifiers.min ? modifier.value-- : undefined"></span>
                    <span class="product-counter__value">{{ modifier.value }}</span>
                    <span class="product-counter__plus" :class="{ 'product-counter__plus--disabled': groupTotalValue >= groupModifiers.max || modifier.value >= groupModifiers.max }" @click="groupTotalValue < groupModifiers.max && modifier.value < groupModifiers.max ? modifier.value++ : undefined"></span>
                </span>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'groupModifiers',
    props: ['groupModifiers'],

    methods: {
        onInput(event, modifier) {
            console.log(event.target.value, modifier);

            if (this.groupModifiers.min === 1 && this.groupModifiers.max === 1) {
                this.groupModifiers.modifiers.forEach((modifier) => {
                    modifier.value = +event.target.value === modifier.id ? 1 : 0;
                })
            } else {
                modifier.value = event.target.checked ? 1 : 0;
            }
        }
    },

    computed: {
        groupTotalValue() {
            return this.groupModifiers.modifiers.reduce((carry, modifier) => {
                return carry + modifier.value;
            }, 0);
        },
    },

    watch: {
        groupTotalValue(value) {
            this.$set(this.groupModifiers, 'groupTotalValue', value);
        }
    },

    mounted() {
        this.groupModifiers.modifiers.forEach((modifier) => {
            this.$set(modifier, 'value', 0);
            this.$set(modifier, 'checked', false);
        });
    }
}
</script>
