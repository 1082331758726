<template>
    <div v-if="isShow" class="modal" :class="[`modal--${$options.name}`]">
        <div class="modal__close btn btn--remove" @click="close"></div>

        <div class="modal__title">
            {{message ?? ''}}
        </div>

        <div class="btn btn--red" @click="close">Отмена</div>
        <div class="btn btn--success" @click="retryCreateOrder">Оформить все равно</div>
    </div>
</template>

<script>

export default {
    name: 'has-active-order',

    methods: {
        close() {
            this.$root.closeModal(this.$options.name);
        },

        retryCreateOrder() {
            this.$emit('retry-create-order')
            this.close()
        }
    },

    computed: {
        isShow() {
            return this.$root.modal.open.name === this.$options.name;
        },
        message() {
            return this.$root.modal.open.data?.message ?? ''
        }
    },

    watch: {
        isShow: function (isShow) {
            if (isShow === false) this.close();
        }
    }
}
</script>
