let storageSupported;

try {
    storageSupported = !!window.localStorage;
} catch (e) {
    storageSupported = false;
}

if (!storageSupported) {
    Object.defineProperty(window, 'localStorage', {
        value: {
            _data: {},
            setItem: function (id, val) {
                return this._data[id] = String(val);
            },
            getItem: function (id) {
                return this._data.hasOwnProperty(id) ? this._data[id] : undefined;
            },
            removeItem: function (id) {
                return delete this._data[id];
            },
            clear: function () {
                return this._data = {};
            }
        }
    });
    Object.defineProperty(window, 'sessionStorage', {
        value: {
            _data: {},
            setItem: function (id, val) {
                return this._data[id] = String(val);
            },
            getItem: function (id) {
                return this._data.hasOwnProperty(id) ? this._data[id] : undefined;
            },
            removeItem: function (id) {
                return delete this._data[id];
            },
            clear: function () {
                return this._data = {};
            }
        }
    });
}

import './vendor';

if (process.env.MIX_APP_ENV === 'production') {
    require('./sentry.js');
}

import generalMixin from './mixins/app/general.js';

import { Hooper, Navigation as HooperNavigation, Slide, Pagination as HooperPagination } from 'hooper';

Vue.filter('numberFormat', (value) => (+value).toLocaleString('ru'));

window.vm = new Vue({
    el: '#app',
    mixins: [generalMixin],
    components: {
        Hooper,
        Slide,
        HooperNavigation,
        HooperPagination
    },
    data: {
        loading: false,
        createdOrder: null,
        weekDays: [
            'Пн',
            'Вт',
            'Ср',
            'Чт',
            'Пт',
            'Сб',
            'Вс',
        ]
    },
    methods: {
        clickSlide(event) {
            if (this.$refs.carousel.isSliding) {
                event.preventDefault()
            }
        },
        getShowWorkingHours(workingHours = []) {
            const data = [];

            workingHours
                .sort((a, b) => a.day - b.day)
                .forEach((item) => {
                    const momentFrom = window.moment().startOf('day').add(item.from, 'minutes');
                    const momentTo = window.moment().startOf('day').add(item.to, 'minutes');

                    const key = `${momentFrom.format('HH:mm')} — ${momentTo.format('HH:mm')}`;
                    const day = this.weekDays[item.day - 1];
                    const dayPrev = item.day > 1 ? this.weekDays[item.day - 2] : null;
                    const interval = data.find((item) => item.time === key && item.days.includes(dayPrev));

                    if (interval) {
                        if (!interval.days.includes(day)) interval.days.push(day);
                    } else {
                        data.push({
                            time: key,
                            days: [ day ]
                        });
                    }
                });

            return data;
        },
    },
    created() {
        if (window.initialState.pageName === 'about') {
            window.onload = () => {
                ymaps.ready(() => {
                    // В функцию поступает пространство имен, которое содержит все запрощенные при инициализации модули.

                    const myMap = new ymaps.Map('del_map', {
                        center: [this.city.coord.latitude, this.city.coord.longitude],
                        zoom: this.city.mapZoom,
                        // В данном примере карта создается без контролов, так как те не были загружены при инициализации API.
                        controls: ["geolocationControl", "fullscreenControl", "zoomControl"]
                    });

                    this.shops.forEach((point, i, arr) => {
                        let balloonContent = `<div class="address">${point.address}</div>`;
                        if (point.geoPoint !== null) balloonContent += `<div class="geoPoint">${point.geoPoint}</div>`;

                        const workingHours = point.workingHours.filter((item) => item.type === 'default' && item.date === null);
                        const showWorkingHours = this.getShowWorkingHours(workingHours);

                        for (const interval of showWorkingHours) {
                            balloonContent += `
                                <div class="work-time" style="display: grid;grid-template-columns: 1fr 3fr;column-gap: 15px;">
                                    <div>${interval.days.length > 1 ? interval.days[0] + ' - ' + interval.days[interval.days.length - 1] : interval.days[0]}</div>
                                    <div>${interval.time === 'null - null' ? 'выходной' : interval.time}</div>
                                </div>
                            `;
                        }

                        const placemark = new ymaps.Placemark([point.coord.latitude, point.coord.longitude], {
                            shopId: point.id,
                            balloonContent: balloonContent
                        }, {
                            iconLayout: 'default#image',
                                iconImageHref: '/images/icons/map-pin--yap.svg',
                                iconImageSize: [36, 44]
                        });

                        myMap.geoObjects.add(placemark);

                        placemark.events.add(['balloonopen', 'balloonclose'], (event) => {
                            const [ latitude, longitude ] = event.get('target').geometry.getCoordinates();
                            $(`.addressList ul li[data-latitude="${latitude}"][data-longitude="${longitude}"]`).toggleClass('active', event.get('type') === 'balloonopen');
                        });
                    });

                    myMap.setBounds(myMap.geoObjects.getBounds(), { zoomMargin: 35 });

                    if (this.shops.length <= 2 || myMap.getZoom() > 17) myMap.setZoom(this.city.mapZoom);

                    $('.addressList ul li').on('click', (event) => {
                        const [ latitude, longitude ] = [ $(event.currentTarget).data('latitude'), $(event.currentTarget).data('longitude') ];
                        ymaps.geoQuery(myMap.geoObjects).searchInside([latitude, longitude]).search('geometry.type == "Point"').get(0).balloon.open();
                        myMap.setCenter([latitude, longitude]);
                    });

                });
            }
        }

        const checkEmptyInput = (event) => event.target.dataset.empty = ['', undefined].includes(event.target.value?.trim());
        document.addEventListener('input', checkEmptyInput, { passive: true });
        document.addEventListener('keyup', checkEmptyInput, { passive: true });

        setTimeout(() => {
            const timerRefs = Object.keys(this.$refs).filter((key) => key.match(/timer\d/)).map((key) => this.$refs[key]);
            if (!timerRefs.length) return;

            timerRefs.forEach((ref) => {
                const timestamp = +ref.dataset.timestamp;
                const finishAt = new Date(timestamp * 1000);

                const interval = setInterval(() => {
                    const now = new Date();
                    const diff = {
                        ms: finishAt - now
                    };

                    diff.sec = Math.floor(diff.ms / 1000);
                    diff.min = Math.floor(diff.sec / 60);
                    diff.hour = Math.floor(diff.min / 60);
                    diff.day = Math.floor(diff.hour / 24);

                    if (diff.day < 0) {
                        ref.closest('.special-offer').remove();
                        clearInterval(interval);
                    }

                    ref.querySelector('.timer__item--min').textContent = ('00' + diff.min % 60).slice(-2);
                    ref.querySelector('.timer__item--hours').textContent = ('00' + diff.hour % 24).slice(-2);
                    ref.querySelector('.timer__item--days').textContent = ('00' + diff.day).slice(-2);
                }, 1000);
            });
        })

        addEventListener('storage', (event) => {
            if (event.key === 'cartHash' && event.newValue !== event.oldValue) {
                this.$eventBus.$emit('actualizeCart')
            }
        })
    },

    computed: {
        deliveryInfo() {
            const price = this.addr.requirements?.sum_to_paid_delivery ?? 0;
            const sumToFree = this.addr.requirements?.sum_to_free_delivery ?? 0;
            const isFree = this.$root.orderType === 'takeaway'
                || this.addr.requirements?.sum_to_free_delivery !== null
                && this.basket.cart.price.subtotal >= this.addr.requirements?.sum_to_free_delivery;

            return {
                price: isFree ? 0 : price,
                sumToFreeDiff: sumToFree - this.basket.cart.price.subtotal,
                minOrder: !this.addr.requirements?.sum_to_free_delivery && this.addr.requirements?.sum_to_min_order || 0
            };
        }
    }
});

$('.city-select__current').click((event) => {
    $(event.target).siblings('.city-select__list').toggleClass('city-select__list--show');
    $('.city-select__current').toggleClass('city-select__current--show');

    $(document.body).one('click', () => {
        $(event.target).siblings('.city-select__list').removeClass('city-select__list--show');
        $('.city-select__current').removeClass('city-select__current--show');
    });
    return false;
});

$('.city-select__icon--open').click((event) => {
    $('.city-select__current').toggleClass('city-select__current--show').siblings('.city-select__list').toggleClass('city-select__list--show');

    $(document.body).one('click', () => {
        $('.city-select__current').toggleClass('city-select__current--show').siblings('.city-select__list').removeClass('city-select__list--show');
    });
    return false;
});

const onMenuItemHover = (event) => {
    const $target = $(event.currentTarget);
    const $childMenu = $target.siblings('.menu__products--child');
    let close = true;

    if ($childMenu.length === 0) return;

    $childMenu.css('display', 'flex');
    $childMenu.one('mouseenter', () => close = false);
    $childMenu.one('mouseleave', () => $childMenu.css('display', ''));

    $target.one('mouseleave', () => {
        setTimeout(() => {
            if (close) $childMenu.css('display', '');
        }, 100);
    });

    return false;
};

if (window.innerWidth <= 425) {
    $('.menu__item').click(onMenuItemHover);

    $('.menu__products--child li').click((event) => {
        $(event.currentTarget).parent().css('display', '');
    })
} else {
    $('.menu__item').hover(onMenuItemHover);
}
function toggleAttributes(button) {
    const $button = $(button);
    const $siteHeaderWrapper = $('.site-header__wrapper');
    const $siteHeader = $('.site-header');
    const $siteMenuMobile = $('.site-menu--mobile');
    const $siteHeaderAfter = $('.site-header__after');

    $siteHeaderWrapper.attr('mobile-menu-show', $siteHeaderWrapper.attr('mobile-menu-show') === undefined ? '' : null);
    $siteHeader.attr('mobile-menu-show', $siteHeader.attr('mobile-menu-show') === undefined ? '' : null);
    $siteMenuMobile.attr('show', $siteMenuMobile.attr('show') === undefined ? '' : null);
    $siteHeaderAfter.attr('mobile-menu-show-after', $siteHeaderAfter.attr('mobile-menu-show-after') === undefined ? '' : null);
    $button.toggleClass('icon--cross');
}
$('.site-header .icon--burger-button').click((event) => {
    toggleAttributes('.site-header .icon--burger-button');
});

$('.site-header__after').click((event) => {
    toggleAttributes('.site-header .icon--burger-button');
});

$('.menu__item--list').click((event) => {
    const $menuItemListParent = $('.menu__item--list');

    $menuItemListParent.attr('mobile-menu-open', $menuItemListParent.attr('mobile-menu-open') === undefined ? '' : null);
});

/** Перезагружаем страницу если последний пинг был больше часа назад */
document.addEventListener('DOMContentLoaded', () => {
    localStorage.setItem('DOMContentLoaded', `${new Date().getTime()}`);

    setInterval(function checkLastPing() {
        if (new Date().getTime() - localStorage.getItem('DOMContentLoaded') > 1_000 * 60 * 60) {
            window.location.reload();
        }

        return checkLastPing;
    }(), 1_000);
});

