import product from '../../components/modals/product';
import callMeBack from '../../components/modals/callMeBack';
import writeBoss from '../../components/modals/writeBoss';
import selectDeliveryAddress from '../../components/modals/selectDeliveryAddress';
import orderCreated from '../../components/modals/orderCreated';
import hasActiveOrder from "../../components/modals/hasActiveOrder.vue";

export default {
    components: {
        productModal: product,
        callMeBackModal: callMeBack,
        writeBossModal: writeBoss,
        selectDeliveryAddressModal: selectDeliveryAddress,
        orderCreatedModal: orderCreated,
        hasActiveOrder: hasActiveOrder
    },

    methods: {
        openModal(name, data = {}) {
            this.modal.open.name = name;
            this.modal.open.data = data;
            this.modal.list[name] = true;

            document.body.classList.add('modal-open');
        },

        closeModal(name) {
            let isModalOpen = this.modal.list[name];

            this.modal.list[name] = false;
            this.modal.open.name = '';
            this.modal.open.data = {};

            if (isModalOpen) {
                document.body.classList.remove('modal-open');
            }
        },

        showAlert(message, title = '') {
            this.openModal('ModalM', {title: title, text: message});
        }
    },

    mounted() {
        $(document).click((event) => {
            if (event.target === document.body) this.closeModal(this.modal.open.name);
        });
    }
}
