<template>
    <div class="uds-page">
        <div class="title">Правила использования сертификатов UDS</div>

        <p>Если вы получили в мобильном приложении UDS App электронный сертификат, то пожалуйста ознакомьтесь с правилами использования.</p>

        <p>Для использования сертификата, в корзине нажмите галочку “Использовать сертификат UDS” и введите в поле “Код сертификата” 6-значный код сертификата при оформлении заказа.</p>

        <p>Код сертификата необходимо посмотреть в разделе «Мои сертификаты» на главной экране приложения UDS App выбрав необходимый сертификат.</p>

        <img src="/images/uds/1.jpg" alt="Мои сертификаты">

        <img src="/images/uds/2.jpg" alt="Мои сертификаты">

        <p>Ваши активные сертификаты отображаются на главном экране приложения UDS App в разделе «Мои сертификаты».</p>

        <img src="/images/uds/3.jpg" alt="Мои сертификаты">

        <p>Выбор всегда за вами. Выбирайте сами: оплатить покупку баллами или сертификатом.</p>

        <p>Срок действия и лимит списания сертификата индивидуальны (от 10 до 100% от суммы чека), условия указаны в каждом сертификате.</p>

        <p>Историю использования сертификатов вы можете посмотреть в настройках аккаунта приложения UDS App в разделе «Сертификаты».</p>

        <p>Если у вас остались вопросы, то отправьте нам сообщение с вопросом через мобильное приложение UDS App.</p>
    </div>
</template>

<script>
export default {
    name: 'UdsInformation'
}
</script>

<style lang="scss" scoped>
.uds-page {
    font-family: Roboto, sans-serif;

    .title {
        color: #333;
        font-size: 48px;
        font-style: normal;
        font-weight: 500;
        margin-bottom: 40px;
    }

    > p {
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px;
        margin-bottom: 24px;
    }

    > img {
        height: 500px;
        margin-bottom: 24px;
    }
}

</style>
