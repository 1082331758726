const ALIAS = 'api';

export function searchForZonesByCoordinates() {
    return axios.post(`${ALIAS}/searchForZonesByCoordinates`, this.$root.addr.coordinates);
}

export function checkAddressForDelivery(address, changeOrderType) {
    return axios.post(`${ALIAS}/checkAddressForDelivery`,
        {
            address: address,
            changeOrderType: changeOrderType
        });
}
