<template>
    <div class="product-counter" :class="{ 'product-counter--transparent': transparent }" :count="product.count">
        <template v-if="single">
            <div class="btn btn--success" @click="sendCart('add')">Купить</div>
        </template>

        <template v-else-if="buttonIfZero && product.count === 0">
            <div class="btn btn--success" @click="sendCart('add')">Добавить</div>
        </template>

        <template v-else-if="buttonIfRecommendation && product.count === 0">
            <div class="product-counter__recommendation currency" @click="sendCart('add')">{{ product.price | numberFormat }}</div>
        </template>

        <template v-else>
            <span class="product-counter__minus" :class="{ 'product-counter__minus--disabled': removeDisabled }" @click="sendCart('remove')"></span>
            <div class="product-counter__value">{{ product.count }}</div>
            <span class="product-counter__plus" :class="{ 'product-counter__plus--disabled': addDisabled }" @click="sendCart('add')"></span>
        </template>
    </div>
</template>
<script>
    export default {
        props: ['product', 'transparent', 'sendInstantly', 'single', 'buttonIfZero', 'buttonIfRecommendation', 'min'],
        methods: {
            sendCart(type) {
                if (this.$props.sendInstantly === false) {
                    if (type === 'add') this.plus();
                    if (type === 'remove') this.minus();
                } else {
                    if (type === 'add' && this.addDisabled) return;
                    if (type === 'remove' && this.removeDisabled) return;

                    this.$eventBus.$emit('sendCart', {
                        type: type,
                        productId: this.product.id,
                        productIndex: this.product.index
                    });
                }
            },

            plus() {
                if (!this.addDisabled) this.product.count++
            },

            minus() {
                if (!this.removeDisabled) this.product.count--;
            },

            writeToProductCount(count) {
                this.product.count = count;
            }
        },

        computed: {
            addDisabled() {
                return this.product.count >= (this.product.maxCount - this.product.saleMultiplicity);
            },
            removeDisabled() {
                if (this.min) {
                    return this.product.count <= this.min;
                }

                return this.product.related ? this.product.count <= 0 : this.product.count === 1 || this.product.count === this.product.saleMultiplicity;
            }
        },
        created() {
            //Подписываемся на уведомление о изменении продукта
            this.$eventBus.$on("renderCartProduct", (goods) => {
                if (typeof goods[this.product.id] !== "undefined") {
                    this.writeToProductCount(goods[this.product.id].count);
                } else {
                    this.writeToProductCount(0);
                }
            });

            if (this.type === 'related') this.writeToProductCount(1);
        },
        watch: {
            'product.count': function (value) {
                if (this.type === 'related' && value < 1) this.writeToProductCount(1);
            }
        }
    }
</script>
