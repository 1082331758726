<template>
    <div class="product-view" :class="{'product-view--without-photo': product.groupModifiers.length}">
        <div class="img" :class="{ 'img--contain': product.group === 9 }" :style="{ 'background-image': `url(${product.img.big})` }" :data-alt="product.name">
            <div class="product-label" v-if="!isMobile">
                <div v-for="label in product.labels" :key="label" :class="`product-label__item product-label__item--${label}`"></div>
            </div>
        </div>

        <div class="product-info__title">{{ product.name }}</div>

        <div class="product-info">
            <div class="product-info__description">{{ product.desc }}</div>
            <div class="product-info__meta" v-if="isMobile">{{ product.quantityInProduct }} шт. / {{ product.weight.value }}&nbsp{{ product.weight.unit }}</div>
            <div class="product-info__nutritional-value">Пищевая ценность 1 порции: {{ nutritionalValue }}</div>

            <div class="product-info__allergen" v-if="product.allergen">
                <hr />
                <div class="product-info__allergen--button" @click="onCheckInfoAllergen">
                    Информация об аллергенах
                    <div class="product-info__allergen--icon">
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"
                             :style="{'transform': checkInfoAllergen ? 'rotate(180deg)' : 'rotate(360deg)'}">
                            <path d="M22 13.2542L15.496 20L9 13.2542" stroke="#00BF53" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                </div>
                <div class="product-info__allergen--info" v-show="checkInfoAllergen">
                    {{ product.allergen }}
                </div>
                <hr />
            </div>
            <div class="product-info__modifiers">
                <div v-for="modifier in product.modifiers">
                    <Modifier :modifier="modifier" />
                </div>
            </div>

            <div class="product-info__group-modifiers">
                <div v-for="groupModifiers in product.groupModifiers">
                    <GroupModifiers v-if="groupModifiers.modifiers.length" :group-modifiers="groupModifiers" />
                </div>
            </div>
        </div>

        <div class="product-footer">
            <div class="product-info__meta" v-if="!isMobile">{{ product.quantityInProduct }} шт. / {{ product.weight.value }}&nbsp{{ product.weight.unit }}</div>
            <product-counter
                v-if="!isMobile || (isMobile && currentBasket && currentBasket?.count !== 0)"
                :product="currentProduct" :transparent="true" :min="1" :sendInstantly="sendInstantly" />
            <button
                v-if="!isMobile || (isMobile && !currentBasket)"
                class="product-info__add-to-cart currency" :disabled="!canAddToCart" @click="canAddToCart ? addToCart() : undefined">{{ productPrice }}</button>
        </div>

        <div class="product-close" @click="close">
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M13.7071 1.70711C14.0976 1.31658 14.0976 0.683418 13.7071 0.292893C13.3166 -0.0976311 12.6834 -0.0976311 12.2929 0.292893L7 5.58579L1.70711 0.292893C1.31658 -0.0976311 0.683418 -0.0976311 0.292893 0.292893C-0.0976311 0.683418 -0.0976311 1.31658 0.292893 1.70711L5.58579 7L0.292893 12.2929C-0.0976311 12.6834 -0.0976311 13.3166 0.292893 13.7071C0.683418 14.0976 1.31658 14.0976 1.70711 13.7071L7 8.41421L12.2929 13.7071C12.6834 14.0976 13.3166 14.0976 13.7071 13.7071C14.0976 13.3166 14.0976 12.6834 13.7071 12.2929L8.41421 7L13.7071 1.70711Z" fill="#8A8A8A"/>
            </svg>
        </div>
    </div>
</template>

<script>
import GroupModifiers from "./groupModifiers.vue";
import Modifier from "./modifier.vue";

export default {
    name: 'product',
    components: { GroupModifiers, Modifier },
    props: ['product'],
    data() {
        return {
            checkInfoAllergen: false,
        }
    },

    methods: {
        addToCart() {
            this.$eventBus.$emit('sendCart', {
                type: 'add',
                productId:
                this.product.id,
                count: this.product.count,

                modifiers: this.product.modifiers,
                groupModifiers: this.product.groupModifiers,
            });

            this.close();
        },
        onCheckInfoAllergen() {
            this.checkInfoAllergen = !this.checkInfoAllergen
        },

        getAllModifiers() {
            return [...this.product.modifiers.filter((modifier) => modifier.value), ...this.product.groupModifiers.reduce((carry, groupModifiers) => {
                return [...carry, ...groupModifiers.modifiers.filter((modifier) => modifier.value)];
            }, [])];
        },
        close() {
            this.checkInfoAllergen = false;
            this.$root.closeModal(this.$options.name);
        }
    },

    computed: {
        nutritionalValue() {
            return [
                `К ${this.product.nutritionalValue100gramm.kkal}`,
                `Б ${this.product.nutritionalValue100gramm.belki}`,
                `Ж ${this.product.nutritionalValue100gramm.zhiry}`,
                `У ${this.product.nutritionalValue100gramm.uglevod}`,
            ].join(', ');
        },
        currentBasket() {
            return this.$root.cartProductsSorted.find((product) => product.id === this.product.id);
        },

        canAddToCart() {
            return !this.product.groupModifiers.find((groupModifiers) => groupModifiers.groupTotalValue < groupModifiers.min);
        },
        isMobile() {
            return this.$root.window.width < 551;
        },

        sendInstantly() {
            return !this.isMobile ? false : !!this.currentBasket;
        },

        currentProduct() {
            return this.isMobile ? this.currentBasket : this.product;
        },

        productPrice() {
            return this.product.price + this.getAllModifiers().reduce((carry, modifier) => {
                return carry + modifier.value * modifier.price;
            }, 0);
        },
    },

    mounted() {
        this.product.count = 1;

        this.product.groupModifiers.forEach((groupModifiers) => {
            groupModifiers.modifiers.forEach((modifier) => {
                this.$set(modifier, 'value', 0);
                this.$set(modifier, 'checked', false);
            })
        })
    }
}
</script>
