<template>
    <div id="zoneMap" style="height: 540px;width: 100%"></div>
</template>

<script>
export default {
    methods: {
        initMap() {
            window.onload = () => {
                ymaps.ready(() => {
                    let myMap = new ymaps.Map('zoneMap', {
                            center: [window.initialState.city.coord.latitude, window.initialState.city.coord.longitude],
                            zoom: window.initialState.city.mapZoom,
                            controls: ['geolocationControl', 'searchControl']
                        })
                    let searchControl = myMap.controls.get('searchControl');

                    searchControl.options.set({noPlacemark: true, placeholderContent: 'Введите адрес доставки'});

                    this.getDeliveryZones(ymaps, myMap);

                    myMap.setBounds(myMap.geoObjects.getBounds());
                })
            }
        },
    },
    created() {
        this.initMap();
    }
}
</script>
