<template>
    <div class="checkout" @submit.prevent="createOrder(true)">

        <div class="checkout__order-type" v-if="$root.city.delivery && $root.shops.length">
            <radio-slider
                v-model="$root.orderType"
                :inputs="orderTypeInputs"
                @input="changeOrderType"/>
        </div>
        <promocode v-if="isShowMobile" />

        <div class="block-group">
            <div class="block-group__title">Контактные данные</div>
            <div class="block-group__items">
                <div class="block-group__item">
                    <input type="text" class="block-group__input" v-model="name" required>
                    <label class="block-group__label">Имя *</label>
                </div>
                <div class="block-group__item">
                    <input v-maska data-maska="+7 (###) ###-##-##" @input="inputPhone" type="tel" class="block-group__input vue-masked" :data-empty="`${!phone}`" ref="phone" v-model="phone" required>
                    <label class="block-group__label">Телефон *</label>
                </div>
                <div class="block-group__item"
                     v-show="isShowMobile && !isDelivery"
                     v-if="!$root.shop.id">
                    <input type="text" class="block-group__input" v-model="$root.shop.id"
                           @focus="$root.openModal('select-delivery-address')" required readonly>
                    <label class="block-group__label">Выберите торговую точку</label>
                </div>
                <div class="selected_address"
                     v-show="isShowMobile && !isDelivery"
                     @click="$root.openModal('select-delivery-address')"
                     v-else>
                    <div class="selected_address_info">
                        <p>{{ $root.shop.name }}</p>
                        <span>{{ $root.shop.address }}</span>
                    </div>
                    <svg style="margin-left: auto; cursor: pointer"
                         width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20.721 9.27754L22.7232 11.2798M22.0082 7.51364L16.5917 12.9302C16.3118 13.2097 16.121 13.5657 16.0431 13.9535L15.5428 16.458L18.0473 15.9567C18.435 15.8792 18.7907 15.689 19.0706 15.4091L24.4871 9.99256C24.6499 9.82979 24.779 9.63656 24.8671 9.42389C24.9552 9.21122 25.0005 8.98329 25.0005 8.7531C25.0005 8.52291 24.9552 8.29498 24.8671 8.08231C24.779 7.86965 24.6499 7.67641 24.4871 7.51364C24.3244 7.35088 24.1311 7.22176 23.9185 7.13367C23.7058 7.04558 23.4779 7.00024 23.2477 7.00024C23.0175 7.00024 22.7896 7.04558 22.5769 7.13367C22.3642 7.22176 22.171 7.35088 22.0082 7.51364Z" stroke="#00BF53" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M22.5599 19.8137V22.9256C22.5599 23.4759 22.3414 24.0035 21.9523 24.3926C21.5632 24.7817 21.0355 25.0003 20.4853 25.0003H9.07474C8.52451 25.0003 7.99681 24.7817 7.60774 24.3926C7.21867 24.0035 7.00009 23.4759 7.00009 22.9256V11.5151C7.00009 10.9648 7.21867 10.4372 7.60774 10.0481C7.99681 9.65901 8.52451 9.44043 9.07474 9.44043H12.1867" stroke="#00BF53" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
            </div>
        </div>

        <div class="block-group" v-if="isDelivery">
            <div class="block-group__title">Адрес доставки</div>
            <div class="block-group__items block-group__items--delivery">
                <div class="block-group__item block-group__item--address">
                    <input type="text" class="block-group__input"
                           v-model="$root.addr.formated"
                           @click="$root.openModal('select-delivery-address')"
                           required readonly>
                    <label class="block-group__label"
                           :class="{'block-group__label-active': !!$root.addr.formated}">Адрес *</label>
                    <div class="block-group__icon">
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M20.721 9.27754L22.7232 11.2798M22.0082 7.51364L16.5917 12.9302C16.3118 13.2097 16.121 13.5657 16.0431 13.9535L15.5428 16.458L18.0473 15.9567C18.435 15.8792 18.7907 15.689 19.0706 15.4091L24.4871 9.99256C24.6499 9.82979 24.779 9.63656 24.8671 9.42389C24.9552 9.21122 25.0005 8.98329 25.0005 8.7531C25.0005 8.52291 24.9552 8.29498 24.8671 8.08231C24.779 7.86965 24.6499 7.67641 24.4871 7.51364C24.3244 7.35088 24.1311 7.22176 23.9185 7.13367C23.7058 7.04558 23.4779 7.00024 23.2477 7.00024C23.0175 7.00024 22.7896 7.04558 22.5769 7.13367C22.3642 7.22176 22.171 7.35088 22.0082 7.51364Z" stroke="#00BF53" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M22.5599 19.8137V22.9256C22.5599 23.4759 22.3414 24.0035 21.9523 24.3926C21.5632 24.7817 21.0355 25.0003 20.4853 25.0003H9.07474C8.52451 25.0003 7.99681 24.7817 7.60774 24.3926C7.21867 24.0035 7.00009 23.4759 7.00009 22.9256V11.5151C7.00009 10.9648 7.21867 10.4372 7.60774 10.0481C7.99681 9.65901 8.52451 9.44043 9.07474 9.44043H12.1867" stroke="#00BF53" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                </div>
                <div class="block-group__item block-group__item--flat">
                    <input type="text" class="block-group__input" v-model="$root.addr.room" required>
                    <label class="block-group__label">Кв/Офис*</label>
                </div>
                <div class="block-group__item block-group__item--entrance">
                    <input type="text" class="block-group__input" v-model="$root.addr.entrance" required>
                    <label class="block-group__label">Подъезд</label>
                </div>
                <div class="block-group__item block-group__item--floor">
                    <input type="text" class="block-group__input" v-model="$root.addr.floor" required>
                    <label class="block-group__label">Этаж</label>
                </div>
                <div class="block-group__item block-group__item--comment">
                    <textarea type="text" v-model="comment" class="block-group__input" required />
                    <label class="block-group__label">Комментарий к заказу</label>
                </div>
            </div>
        </div>
        <div class="block-group" v-show="!isShowMobile" v-else>
            <div class="block-group__title">Адрес самовывоза</div>
            <div class="block-group__items">
                <div class="block-group__item" v-if="!$root.shop.id">
                    <input type="text" class="block-group__input" v-model="$root.shop.id"
                           @focus="$root.openModal('select-delivery-address')" required readonly>
                    <label class="block-group__label">Выберите торговую точку</label>
                </div>
                <div class="selected_address"
                     @click="$root.openModal('select-delivery-address')"
                     v-else>
                    <div class="selected_address_info">
                        <p>{{ $root.shop.name }}</p>
                        <span>{{ $root.shop.address }}</span>
                    </div>
                    <svg style="margin-left: auto; cursor: pointer"
                         width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20.721 9.27754L22.7232 11.2798M22.0082 7.51364L16.5917 12.9302C16.3118 13.2097 16.121 13.5657 16.0431 13.9535L15.5428 16.458L18.0473 15.9567C18.435 15.8792 18.7907 15.689 19.0706 15.4091L24.4871 9.99256C24.6499 9.82979 24.779 9.63656 24.8671 9.42389C24.9552 9.21122 25.0005 8.98329 25.0005 8.7531C25.0005 8.52291 24.9552 8.29498 24.8671 8.08231C24.779 7.86965 24.6499 7.67641 24.4871 7.51364C24.3244 7.35088 24.1311 7.22176 23.9185 7.13367C23.7058 7.04558 23.4779 7.00024 23.2477 7.00024C23.0175 7.00024 22.7896 7.04558 22.5769 7.13367C22.3642 7.22176 22.171 7.35088 22.0082 7.51364Z" stroke="#00BF53" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M22.5599 19.8137V22.9256C22.5599 23.4759 22.3414 24.0035 21.9523 24.3926C21.5632 24.7817 21.0355 25.0003 20.4853 25.0003H9.07474C8.52451 25.0003 7.99681 24.7817 7.60774 24.3926C7.21867 24.0035 7.00009 23.4759 7.00009 22.9256V11.5151C7.00009 10.9648 7.21867 10.4372 7.60774 10.0481C7.99681 9.65901 8.52451 9.44043 9.07474 9.44043H12.1867" stroke="#00BF53" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
            </div>
        </div>
        <div class="block-group">
            <div class="block-group__title">Время получения заказа</div>
            <div class="block-group__items block-group__items--date_time"
                 :class="{'block-group__items--date_time_soon': orderTimeType === 'soon'}">
                <div class="block-group__item block-group__item--select_time">
                    <dropdown-menu :items="[
                                        {id: 1, name: 'Ближайшее время', type: 'soon'},
                                        {id: 2, name: 'В определенное время', type: 'time'}
                                   ]"
                                   :key-value="'type'"
                                   @change="checkPromocode"
                                   v-model="orderTimeType" />
                </div>
                <div v-if="isShowMobile" class="block-group__item" v-show="orderTimeType === 'time'">
                    <input type="date" class="block-group__input"
                           v-model="currentDate"
                           @change="changeCurrentDate" />
                    <label class="block-group__label">Укажите дату</label>
                </div>
                <div v-else class="shipping-date-time" v-show="orderTimeType === 'time'">
                    <label class="shipping-date-time__label">
                        <input type="datetime-local" ref="shippingDateTime"
                               :required="orderTimeType === 'time'">
                        <span>Укажите дату</span>
                        <svg width="24" style="position: absolute; right: 18px" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M16 2C16.5523 2 17 2.44772 17 3V4H19C20.6022 4 22 5.24032 22 6.88889V11V20.1111C22 21.7597 20.6022 23 19 23H5C3.39777 23 2 21.7597 2 20.1111V11V6.88889C2 5.24032 3.39777 4 5 4H7V3C7 2.44772 7.44771 2 8 2C8.55228 2 9 2.44772 9 3V4H15V3C15 2.44772 15.4477 2 16 2ZM7 6V7C7 7.55228 7.44771 8 8 8C8.55228 8 9 7.55228 9 7V6H15V7C15 7.55228 15.4477 8 16 8C16.5523 8 17 7.55228 17 7V6H19C19.6069 6 20 6.45105 20 6.88889V10H4V6.88889C4 6.45105 4.39309 6 5 6H7ZM20 12V20.1111C20 20.5489 19.6069 21 19 21H5C4.39309 21 4 20.5489 4 20.1111V12H20Z" fill="#00BF53"/>
                        </svg>
                    </label>
                </div>
                <div class="block-group__item block-group__item--time" v-show="orderTimeType === 'time'">
                    <dropdown-menu :items="timeCounting"
                                   :is-time="true"
                                   :key-value="'value'"
                                   v-model="currentTime" @change="checkPromocode" />
                </div>
                <div v-if="($root.addr.formated && $root.orderType === 'delivery') || ($root.orderType === 'takeaway' && $root.shop.id)"
                     class="block-group__order-ready" v-show="orderTimeType === 'soon' && orderReadyTime" >
                    <div class="block-group__order-ready-text">
                        <p>Ваш заказ будет готов ~ через</p>
                        <span>Но может получится даже быстрее</span>
                    </div>
                    <div class="block-group__order-ready-time">{{ orderReadyTime }} минут</div>
                </div>
            </div>
        </div>
        <div class="block-group" v-if="isShowMobile">
            <div class="block-group__title">Способ оплаты</div>
            <type-payment />
        </div>

        <label class="privacy-policy mt-4" style="grid-area: privacy-policy">
            <input type="checkbox" v-model="accept" required>
            <span>Согласен с <a href="/terms" class="link link--black link--underline" target="_blank">политикой конфиденциальности</a></span>
        </label>

        <div class="modal-mobile__margin-top">
            <modal-mobile v-show="isShowMobile" />
        </div>

        <dialog-info :visible="dialogInfo.visible"
                     :title="dialogInfo.title"
                     :message="dialogInfo.message"
                    @close="dialogInfo.visible = false" />
        <dialog-error :visible="dialogError.visible"
                      :title="dialogError.title"
                      :message="dialogError.message"
                      @close="dialogError.visible = false" />
        <has-active-order @retry-create-order="retryCreateOrder" />
        <forgot-to-add-modal :visible="forgotToAddModal.visible" @create-without-spices="createOrder" @close="closeForgotToAddModal" />
    </div>
</template>

<script>
import radioSlider from '../radio-slider';
import addressSelect from '../address-select';
import * as Sentry from '@sentry/browser';
import { Mask, MaskInput, vMaska } from 'maska';
import HasActiveOrder from "../modals/hasActiveOrder.vue";
import ForgotToAddModal from "../modals/forgotToAddModal.vue";
import { getCustomCookingTime } from "../../axios/axios_time";
import { searchForZonesByCoordinates } from "../../axios/axios_geocoder";
import ModalMobile from "./modalMobile.vue";
import { debounce } from "../../helpers";

export default {
    directives: {
        maska: vMaska,
    },
    components: {
        ModalMobile,
        ForgotToAddModal,
        HasActiveOrder,
        radioSlider,
        addressSelect
    },
    props: {
        window: {
            type: Object,
            default() {
                return {
                    width: 0,
                    height: 0
                };
            }
        }
    },

    data: function () {
        return {
            orderTypes: [
                {
                    name: 'Доставка',
                    slug: 'delivery'
                },
                {
                    name: 'Самовывоз',
                    slug: 'takeaway'
                }
            ],

            accept: false,
            name: '',
            phone: '',
            shippingDateTime: null,
            orderTimeType: null,
            comment: '',
            dialogError: {
                visible: false,
                title: '',
                message: ''
            },
            createAnyway: false,
            forgotToAddModal: {
                visible: false
            },
            dialogInfo: {
                visible: false,
                title: '',
                message: ''
            },
            currentTime: '',
            currentDate: '',
            actualaizeBasketDebounce: debounce(this.actualaizeBasket, 500),
            orderReadyTimeShop: null,
        };
    },

    methods: {
        searchForZonesByCoordinates,
        getCustomCookingTime,
        async createOrder(checkSpices = false) {
            if (checkSpices && this.hasRelatedProducts && !this.orderWithSpices()) {
                this.openForgotToAddModal()
                return
            }

            this.$parent.processing = true;

            if (this.$root.uds.key) {
                await this.applyUds(this.$root.uds.key, this.$root.uds.receipt.points)
            }

            axios.post('/basket/create', this.payload)
                .then((response) => {
                    if (!response.data.orderId) return;

                    this.$root.createdOrder = response.data;
                    this.$root.createdOrder.payload = this.payload;

                    if (this.$root.createdOrder?.payment?.slug === 'sbp') {
                        this.$root.cacheStorage('set', 'createdOrder', this.$root.createdOrder, moment().add(15, 'minutes'))
                        window.location.replace('/basket/pay-by-qr')
                        return;
                    }

                    this.$root.openModal('order-created');
                    if (response.data.orderId) this.$root.openModal('order-created');

                    this.$eventBus.$emit('order-created');
                })
                .catch((error) => {
                    if (!error.response) {
                        Sentry.captureException(error);
                        alert('Что-то пошло не так. Попробуйте еще раз');
                        return;
                    }

                    if (error.response.data.errors && error.response.data.errors?.activeOrder) {
                        this.$root.openModal('has-active-order', { message: error.response.data.message })
                        return;
                    }

                    if (error.response.data.errors && error.response.data.errors.date) {
                        this.dialogInfo.visible = true;
                        this.dialogInfo.title = error.response.data.errors.date[0];
                        this.dialogInfo.message = error.response.data.message;
                        return;
                    }

                    const errors = error.response.data.errors ? [ { message: this.getErrorsMessage(error.response.data.errors) } ]  : [ { message: error.response.data.message } ];

                    if (!errors.length) {
                        errors.push({ message: 'Произошла ошибка при выполнении запроса' });
                        Sentry.captureException(error);
                    }

                    this.dialogInfo.title = 'Внимание';
                    this.dialogInfo.message = errors.map((item) => item.message).filter((item) => item).join('<br />');
                    this.dialogInfo.visible = true;
                })
                .then(() => {
                    this.$parent.processing = false;
                    this.createAnyway = false
                });
        },

        getErrorsMessage(errors) {
            if (Array.isArray(errors)) {
                return errors.map((error) => error.message).join('<br />');
            }

            return Object.entries(errors).map((error) => error[1][0]).filter((error) => error).join('<br />');
        },

        orderWithSpices() {
            const relatedProductsIds = Object.keys(this.$root.basket.cart.related)
            if (!relatedProductsIds.length)
                return true

            const goodsIds = Object.keys(this.$root.basket.cart.goods).map(key => {
                return String(this.$root.basket.cart.goods[key].id)
            })

            for (const goodsIdsKey of goodsIds) {
                if (relatedProductsIds.includes(goodsIdsKey))
                    return true
            }
            return false
        },
        retryCreateOrder() {
            this.createAnyway = true
            this.createOrder()
        },

        async applyPromocode(promocode) {
            if (!promocode) {
                return;
            }

            if (this.$root.uds.applied) {
                this.promocodeAndUdsWarning();
                this.$root.promocode.key = '';
                return;
            }

            promocode = promocode.replace(' ', '');
            this.$parent.processing = true;

            const response = (await axios.get('/api/order/promocode/apply', {
                params: {
                    order_type: this.$root.orderType,
                    promocode: promocode,
                    shop_id: this.$root.shop.id,
                    order_time_type: this.orderTimeType,
                    order_date: this.currentDate,
                    timezone: this.$root.city.timeZoneOffsetInHours,
                    time: this.payload.time
                }
            })
                .catch((error) => ({ data: { success: false, error: { message: error.message } } }))
            ).data;
            this.$parent.processing = false;
            if (!response.success) {
                this.$root.promocode.error = response?.error?.message ?? 'Упс... Что-то пошло не так :(';

                this.showPromoCodeError(this.$root.promocode.error);

                await this.clearPromocode();

                return;
            }

            this.$root.basket = await this.getCart();

            this.$nextTick(() => {
                this.checkConditionsPromocode();
            });
        },

        async showPromoCodeError(message) {
            this.dialogError.visible = true;
            this.dialogError.title = 'Ошибка промокода';
            this.dialogError.message = message;
        },

        async checkConditionsPromocode() {
            let error = false;

            const conditions = this.appliedPromocode?.item?.conditions || [];
            conditions.forEach((condition) => {
                switch (condition.type) {
                    case 'typeOrder':
                        if (!condition.value.includes(this.$root.orderType)) {
                            error = true;
                        }
                        break;
                    case 'orderPrice':
                        if (condition.value > this.$root.basket.cart.price.subtotal) {
                            error = true;
                        }
                        break;
                    case 'shopIds':
                        if (!condition.value.includes(this.$root.shop.id)) {
                            error = true;
                        }
                        break;
                    case 'today':
                        let now = window.moment().startOf('day');
                        let orderDate = window.moment(this.currentDate).startOf('day');

                        if (this.orderTimeType === 'soon') {
                            break;
                        }

                        if (
                            !this.orderTimeType ||
                            !this.currentDate ||
                            (this.orderTimeType === 'time' && now.diff(orderDate, 'days') !== 0)
                        ) {
                            error = true;
                        }

                        break;
                    case 'timeLimit':
                        let timeStart = window.moment(condition.value.start, 'h:mm');
                        let timeEnd = window.moment(condition.value.end, 'h:mm');

                        if (this.$root.orderType === 'delivery' || (this.$root.orderType === 'takeaway' && this.payload.time.type !== 'soon')) {
                            let currentTime = window.moment(this.payload.time.value.time, 'h:mm');

                            if (!currentTime.isBetween(timeStart, timeEnd))
                            {
                                if (currentTime.isSameOrAfter(timeStart) && currentTime.isSameOrBefore(timeEnd)) {
                                    break;
                                }

                                error = true;
                            }
                            break;
                        }
                        let orderDateTime = window.moment();

                        if (!orderDateTime.isBetween(timeStart, timeEnd)) {
                            error = true;
                        }
                        break;
                    case 'orderInProducts':
                        const cartIds = Object.keys(this.$root.basket.cart.goods).map(key => this.$root.basket.cart.goods[key].id);
                        if (condition.value.filter(item => cartIds.includes(item)).length !== condition.value.length) {
                            error = true
                        }
                        break;
                    case 'weekDay':
                        if (!condition.value.includes(window.moment().isoWeekday().toString())) {
                            error = true;
                        }
                        break;
                }
            })

            this.$root.promocode.error = error ? 'Условия активации не соблюдены' : '';

            return error;
        },

        async changeOrderType(value) {
            this.$root.loadingUds = true;

            if (value === 'delivery') {
                this.$root.shop = {
                    id: null,
                    name: '',
                    address: '',
                    payment_type: []
                };
                localStorage.removeItem('shop');
            }

            this.$nextTick(() => {
                this.checkConditionsPromocode()
                    .then((isError) => {
                        if (isError) {
                            this.showPromoCodeError(this.$root.promocode.error);
                            this.clearPromocode();
                        }
                    });
            });
        },

        checkPromocode() {
            this.$nextTick(() => {
                this.checkConditionsPromocode()
                    .then((isError) => {
                        if (isError) {
                            this.showPromoCodeError(this.$root.promocode.error);
                            this.clearPromocode();
                        }
                    });
            });
        },

        changeCurrentDate() {
            this.$nextTick(() => {
                this.checkConditionsPromocode()
                    .then((isError) => {
                        if (isError) {
                            this.showPromoCodeError(this.$root.promocode.error);
                            this.clearPromocode();
                        }
                    });
            });
        },
        async clearPromocode() {
            const responseClear = (await axios.get('api/order/promocode/clear')).data;
            if (!responseClear.success) return alert('Упс... Что-то пошло не так :(');

            this.$root.basket = await this.getCart();

            this.$nextTick(() => {
                this.$root.promocode = {
                    key: '',
                    error: null
                };
            });
        },

        async getCart() {
            return axios.post('/basket/actualize', {
                changeOrderType: this.$root.orderType,
            })
                .catch((error) => {
                    if (error.message === 'canceled') {
                        return alert('Упс... Кажется у вас отключены cookie! Мы не сможем гарантировать работу сайта, пока вы их не включите :(')
                    } else {
                        Sentry.captureException(error);
                    }
                })
                .then((response) => response?.data || this.$root.basket)
                .finally(() => this.$root.loadingUds = false);
        },

        clearUds() {
            this.$root.uds.key = '';
            this.$root.uds.applied = false;
            this.$root.uds.errors = [];
            this.$root.uds.customer = {
                cashbackRate: null,
                points: null,
                membershipTier: {
                    maxScoresDiscount: null,
                    name: null,
                    uid: null,
                },
            };
        },

        async promocodeAndUdsWarning() {
            this.dialogInfo.title = 'Внимание';
            this.dialogInfo.message = 'Нельзя одновременно использовать промокод и списывать баллы UDS';
            this.dialogInfo.visible = true;
        },

        async applyUds(code, points = 0) {
            if (this.appliedPromocode) {
                this.promocodeAndUdsWarning();
                this.uds.key = '';
                return;
            }

            this.$parent.processing = true;

            const total = this.$root.basket.cart.price.total - (this.$root.promocode.error ? (this.appliedPromocode?.item?.sum || 0) : 0)

            const response = await axios.post('/api/uds/calculateOperation', {
                code: code.replace(' ', ''),
                total: total,
                points: points,
            })
                .catch((error) => {
                    if (!error?.response || !error?.response?.data?.errors) {
                        Sentry.captureException(error);

                        return {
                            data: {
                                errors: [
                                    {
                                        message: 'Упс... Что-то пошло не так. Попробуйте еще раз :('
                                    }
                                ]
                            }
                        };
                    }

                    return error.response;
                });

            this.$parent.processing = false;

            this.$root.uds.errors = response.data.errors || [];

            if (this.$root.uds.errors.length === 0 && this.$root.uds.needCertificate && response.data.purchase.certificatePoints === 0) {
                this.$root.uds.errors.push({
                    message: 'Вы хотите использовать сертификат UDS, но ввели код скидки.'
                });
            }

            if (this.$root.uds.errors.length === 0 && !this.$root.uds.needCertificate && response.data.purchase.certificatePoints !== 0) {
                this.$root.uds.errors.push({
                    message: 'Вы ввели код сертификата, но ожидался код скидки.'
                });
            }

            if (this.$root.uds.errors.length !== 0) {
                this.dialogError.visible = true;
                this.dialogError.title = 'Ошибка UDS';
                this.dialogError.message = this.$root.uds.errors.map((error) => error.message).join(' ');
            }

            this.$root.uds.applied = this.$root.uds.errors.length === 0;

            if (this.$root.uds.applied) {
                this.$root.uds.customer = response.data.user;
                this.$root.uds.purchase = response.data.purchase;
                this.$root.uds.receipt.points = Math.floor(response.data.purchase.maxPoints);
                this.$root.uds.receipt.total = response.data.purchase.total;

                this.$root.uds.customer.points = Math.floor(this.$root.uds.customer.points);
                this.$root.uds.purchase.maxPoints = Math.floor(this.$root.uds.purchase.maxPoints);
                this.$root.uds.purchase.points = Math.floor(this.$root.uds.purchase.points);
                this.$root.uds.purchase.pointsPercent = Math.floor(this.$root.uds.purchase.pointsPercent);
                this.$root.uds.purchase.total = Math.floor(this.$root.uds.purchase.total)
                this.$root.uds.purchase.cash = Math.ceil(this.$root.uds.purchase.cash)

                if (this.$root.uds.purchase.certificatePoints > 0) {
                    this.$root.uds.receipt.points = Math.floor(this.$root.uds.purchase.maxPoints);
                }
            }
        },

        setCash(value) {
            this.$root.payment.cash = value
        },

        checkDateTime(date) {
            const timestr = date.substring(11)
            const select = window.moment().startOf('day')
            select.add(timestr.substring(0,2), 'hours').add(timestr.substring(3), 'minutes')

            let from = null
            let to = null

            if (this.isDelivery) {
                const workingTime = this.$root.deliveryWorkingTime[0]
                from = window.moment().startOf('day').add(workingTime.from, 'minutes')
                to = window.moment().startOf('day').add(workingTime.to, 'minutes')
            } else {
                if (!this.$root.shop.id) {
                    return false;
                }
                const workingTime = this.shop.workingHours
                    .filter(item => item.date === null)
                    .filter(item => item.type === 'default')
                    .find(item => {
                        return item.day === 1
                    })

                from = window.moment().startOf('day').add(workingTime.from, 'minutes')
                to = window.moment().startOf('day').add(workingTime.to, 'minutes')
            }
            return select.isBetween(from, to, 'minutes', '[]')
        },

        closeForgotToAddModal() {
            this.forgotToAddModal.visible = false
        },
        openForgotToAddModal() {
            this.forgotToAddModal.visible = true
        },
        async getOrderData() {
            await this.applyPromocode(this.$root.promocode.key ? this.$root.promocode.key : this.$root.appliedPromocode?.key);
        },
        async actualaizeBasket() {
            this.$root.basket = await this.getCart();
        },
        inputPhone($event) {
            const mask = new Mask({
                mask: "+7 (###) ###-##-##"
            })

            if ($event.target.type === 'tel' && $event.isTrusted) {
                $event.target.value = $event.target.value.replace(/\D/g,'').replace(/^(8|78)(9\d+)/g, '7$2');
                this.phone = mask.masked($event.target.value);
            }
        },
    },

    computed: {
        defaultDate() {
            return window.moment().add(this.timeCounting?.length === 0 ? 1 : 0, 'days').format('YYYY-MM-DD');
        },
        payment() {
            return this.$root.payment;
        },
        totalBasket() {
            return this.$root.basket.cart.price.total - (this.$root.uds.applied ? this.$root.uds.receipt.points : 0);
        },
        orderReadyTime() {
            if (this.$root.orderType === 'delivery') {
                if (!this.$root.cookingTimeDelivery) {
                    return this.$root.basket.requirements.deliveryMin;
                }

                return this.$root.cookingTimeDelivery;
            }

            if (!this.$root.shop.customCookingTime) {
                return this.$root.basket.requirements.cookingMin;
            }

            return this.orderReadyTimeShop;
        },
        hasUds() {
            return window.initialState.hasUds;
        },
        isShowMobile() {
            return this.$root.window.width <= 950;
        },

        orderTypeInputs() {
            return this.orderTypes.map((orderType) => ({
                label: orderType.name,
                value: orderType.slug
            }));
        },
        timeCounting() {
            const step = 15; // Шаг в минутах, через который будет чередоваться время
            const minTime = this.time.minTime;
            let endTime = window.moment(this.time.maxTime, 'HH:mm');
            let timesArray = [];
            let currentTime = null; // Текущее время от которого будет строиться массив
            const minutesAdded = this.orderReadyTime; // Минуты времени готовки, которые будут добавлены к текущему массиву

            currentTime = window.moment(minTime, 'HH:mm').add(minutesAdded, 'minutes');

            // Если текущее время больше конечного времени
            if (endTime.isBefore(currentTime)) {
                endTime.add(1, 'day');
            }

            while (currentTime.isSameOrBefore(endTime)) {
                // Условие принимает числа, которые ((заканчиваются на 0 или 5) и (делится на 15))
                // Например: выпало число 3 - оно не заканчивается на 0 или 5; выпало число 5 - оно не делится на 15; выпало число 15 - это подходит
                if (currentTime.minute() % 15 === 0 || currentTime.minute() % 15 === 5 &&
                    (currentTime.minute() % 15 === 1 || currentTime.minute() % 15 === 2 ||
                        currentTime.minute() % 15 === 3 || currentTime.minute() % 15 === 4)
                ) {
                    timesArray.push({
                        id: timesArray.length,
                        name: currentTime.format('HH:mm'),
                        value: currentTime.format('HH:mm')
                    });

                    currentTime.add(step, 'minutes');
                    continue;
                }

                // Прибавляем по минуте к текущему времени, пока не дойдем до условия
                currentTime.add(1, 'minutes')
            }

            return timesArray;
        },

        isDelivery() {
            return this.$root.orderType === 'delivery'
        },

        canOrderSubmit() {
            const payload = this.payload;
            let canOrderSubmit = true;

            canOrderSubmit = canOrderSubmit && !!payload.name;
            canOrderSubmit = canOrderSubmit && !!payload.phone;
            canOrderSubmit = canOrderSubmit && !!this.accept;
            canOrderSubmit = canOrderSubmit && !!payload.payment.slug;
            canOrderSubmit = canOrderSubmit && !!payload.time.type;
            canOrderSubmit = canOrderSubmit && !this.$parent.processing

            if (payload.type === 'delivery') {
                canOrderSubmit = canOrderSubmit && !!payload.delivery.found;
                canOrderSubmit = canOrderSubmit && !!payload.delivery.delivered;
                canOrderSubmit = canOrderSubmit && !!payload.delivery.formated;
                canOrderSubmit = canOrderSubmit && (this.$root.basket.cart.price.total >= this.$root.deliveryInfo.minOrder);
            } else {
                canOrderSubmit = canOrderSubmit && !!payload.shop.id;
            }

            if (payload.time.type === 'time') {
                canOrderSubmit = canOrderSubmit && !!payload.time.value.date;
                canOrderSubmit = canOrderSubmit && !!payload.time.value.time;
            }

            if (payload.payment.slug === 'cash') {
                canOrderSubmit = canOrderSubmit && !(payload.payment.change === null || payload.payment.change === '')
            }

            return canOrderSubmit;
        },

        appliedPromocode() {
            return this.$root.basket.cart.promocode;
        },

        payload() {
            if (isNaN(this.shippingDateTime)) this.shippingDateTime = null;

            const payload = {
                phone: this.phone,
                accountPhone: this.phone,
                name: this.name,
                comment: this.comment,
                city_id: this.$root.city.id,

                type: this.$root.orderType,
                shop: { id: this.$root.shop.id },

                time: {
                    type: this.orderTimeType,
                    value: {
                        date: this.payloadDateAndTime.date,
                        time: this.payloadDateAndTime.time
                    }
                },

                payment: {
                    id: this.payment?.id,
                    slug: this.payment?.slug,
                    subtotal: this.$root.basket.cart.price.subtotal,
                    delivery: this.$root.deliveryInfo.price,
                    total: this.$root.basket.cart.price.total - (this.$root.uds.applied ? this.$root.uds.receipt.points : 0),
                    change: this.$root.payment.cash
                },

                delivery: this.isDelivery ? this.$root.addr : false,
                createAnyway: this.createAnyway,
                channel: 'desktop',
                accept: this.accept
            };

            if (this.$root.uds.applied) {
                payload.uds = {
                    code: this.$root.uds.key,
                    points: this.$root.uds.receipt.points,
                    cash: this.$root.uds.purchase.cash,
                    total: this.$root.uds.purchase.total
                };
            }

            return payload;
        },

        payloadDateAndTime() {
            let result = {
                date: null,
                time: null
            };
            const minutesAdded = this.orderReadyTime;

            if (this.orderTimeType === 'soon') {
                if (this.timeCounting.length > 0) {
                    const now = window.moment().add(minutesAdded, 'minutes');

                    for(let i = 0; i < this.timeCounting.length; i++) {
                        const time = this.timeCounting[i];
                        const specificTime = window.moment(time.value, 'HH:mm');

                        if (specificTime.isAfter(now)) {
                            result = {
                                date: now.format('YYYY-MM-DD'),
                                time: time.value
                            };
                            break;
                        }
                    }

                    if (!result.date || !result.time) {
                        this.currentDate = now.add(1, 'day').format('YYYY-MM-DD');

                        result = {
                            date: this.currentDate,
                            time: this.timeCounting[0].value
                        };
                    }
                }
            } else {
                result = {
                    date: this.currentDate,
                    time: this.currentTime
                };
            }

            return result;
        },

        isCashPayment() {
            return this.payment?.slug === 'cash'
        },

        shop() {
            return this.$root.shop;
        },

        time() {
            const startOfDay = window.moment().startOf('day');
            let workingTime = null;
            if (this.isDelivery) {

                // Если человек выбрал зону доставки, то ищем торговую точку, которая к ней привязана.
                // Если зона не выбрана, то по дефолту время на доставку берем у города.
                if (this.$root.addr.zoneId) {
                    let currentZone = this.$root.deliveryZone.find((zone) => zone.id === this.$root.addr.zoneId);

                    if (!currentZone && this.$root.addr.zoneId) {
                        this.$root.addr = structuredClone(this.$root.defaultAddress)

                        this.$root.cacheStorage('set', 'deliveryAddress', {
                            address: this.$root.addr
                        });
                    }

                    // Если время у торгово точки отсутствует, то берем время на доставку у города.
                    if (currentZone && currentZone.workingHours) {
                        const workingTimeZone = currentZone.workingHours.filter(item => item.date === null)
                            .filter(item => item.type === 'online_orders')
                            .find(item => {
                                return item.day === (this.currentDate ? window.moment(this.currentDate).isoWeekday() : 1)
                            });

                        // Сравнивает с основным интервалом доставки по городу.
                        // Если какой-то интервал торговой точки не входит в основной интервал доставки по городу, то обрезаем ненужную часть.
                        workingTime = {
                            to: Math.min(workingTimeZone.to, this.$root.deliveryWorkingTime[0].to),
                            from: Math.max(workingTimeZone.from, this.$root.deliveryWorkingTime[0].from)
                        };
                    } else {
                        workingTime = this.$root.deliveryWorkingTime[0];
                    }
                } else {
                    workingTime = this.$root.deliveryWorkingTime[0];
                }

            } else {
                if (!this.shop.id) {
                    return {
                        minTime: '00:00',
                        maxTime: '23:59'
                    };
                }
                workingTime = this.shop.workingHours
                    .filter(item => item.date === null)
                    .filter(item => item.type === 'online_orders')
                    .find(item => {
                        return item.day === (this.currentDate ? window.moment(this.currentDate).isoWeekday() : 1)
                    });
            }

            const from = startOfDay.clone().add(workingTime.from, 'minutes');
            const to = startOfDay.clone().add(workingTime.to, 'minutes');

            const time = {
                minTime: null,
                maxTime: to.format('HH:mm')
            };

            const timeZoneOffsetInHours = this.$root.city.timeZoneOffsetInHours;
            const now = window.moment().utc().add(timeZoneOffsetInHours, 'hours');

            if (now > from && (now.format("YYYY-MM-DD") === this.currentDate || !this.currentDate)) {
                time.minTime = window.moment().utc().add(timeZoneOffsetInHours, 'hours').format('HH:mm');
            } else {
                time.minTime = from.utc().add(timeZoneOffsetInHours, 'hours').format('HH:mm');
            }

            return time;
        },
        changeTime() {
            return JSON.stringify(this.time)
        },
        hasRelatedProducts() {
            return !!Object.keys(this.$root.basket.cart.related).length
        }
    },

    mounted() {
        this.getOrderData();
        $(this.$refs.shippingDateTime).attr('type', 'text');
        $(this.$refs.shippingDateTime).attr('pattern', '[0-9]{2}.[0-9]{2}.[0-9]{4}');
        $(this.$refs.shippingDateTime).attr('data-empty', 'false');

        this.currentDate = this.defaultDate;
        $(this.$refs.shippingDateTime).datetimepicker('setDate', this.currentDate);

        $(this.$refs.shippingDateTime).datetimepicker({
            scrollMonth: false,
            scrollInput: false,
            dayOfWeekStart: 1,
            format: 'd.m.Y',
            formatDate: 'Y-m-d',
            value: this.currentDate,
            allowBlank: false,
            mask: true,
            timepicker: false,
            minDate: 0,
            closeOnDateSelect:false,
            onChangeDateTime: (date) => {
                if (!date) {
                    return;
                }

                this.currentDate = window.moment(date).format('YYYY-MM-DD');
                this.changeCurrentDate();
                this.shippingDateTime = date;
            }
        }).val(this.defaultDate).change((event) => {
            event.target.dataset.empty = ['', undefined].includes(event.target.value?.trim());

            if (event.target.dataset.empty === true || event.target.dataset.empty === 'true') {
                $(this.$refs.shippingDateTime).datetimepicker('reset')
            }
        });

        new MaskInput('.vue-masked');

        $('.readonly').on('keydown paste mousedown', (e) => {
            // ignore tabs
            if (e.keyCode !== 9) e.preventDefault();
        });

        this.checkConditionsPromocode();
        this.$eventBus.$on('addressSelected', () => {
            this.actualaizeBasket();
        });

        if (this.$root.addr.formated && (!this.$root.addr.house || !this.$root.addr.street || !this.$root.addr.zoneId)) {
            this.$root.addr = structuredClone(this.$root.defaultAddress)

            this.$root.cacheStorage('set', 'deliveryAddress', {
                address: this.$root.addr
            });
        }

        if (this.$root.addr.coordinates) {
            this.searchForZonesByCoordinates()
                .then(({data}) => {
                    this.$root.addr.requirements = {
                        sum_to_paid_delivery: data.requirements.sum_to_paid_delivery,
                        sum_to_free_delivery: data.requirements.sum_to_free_delivery,
                        sum_to_min_order: data.requirements.sum_to_min_order,
                        delivery_time_min: data.requirements.delivery_time_min
                    };

                    this.actualaizeBasket();
                });
        }

        if (this.shop && !this.isDelivery) {
            this.getCustomCookingTime(this.shop.id)
                .then(({data}) => this.orderReadyTimeShop = data.minutes);
        }
    },

    watch: {
        changeTime() {
            if (window.screen.width > 549 && $(this.$refs.shippingDateTime)) {
                $(this.$refs.shippingDateTime).datetimepicker('setOptions', {minTime: this.time.minTime, maxTime: this.time.maxTime})
            }
        },
        orderReadyTime(val) {
            if (val && this.timeCounting.length > 0) {
                this.currentTime = this.timeCounting[0].value;
            }
        },

        totalBasket(val, oldValue) {
            if (val < oldValue) {
                this.$nextTick(() => {
                    this.checkConditionsPromocode()
                        .then((isError) => {
                            if (isError) {
                                this.clearPromocode();
                            }
                    });
                });
            }
        },

        orderTimeType() {
            if (this.orderTimeType === 'soon') {
                $(this.$refs.shippingDateTime)?.attr('pattern', null);
            } else {
                $(this.$refs.shippingDateTime).attr('pattern', '[0-9]{2}.[0-9]{2}.[0-9]{4} [0-9]{2}:[0-9]{2}')
            }
        },

        phone(value) {
            if (value.replace(/\D/g, '') === '789') {
                this.phone = value.replace('89', '9');
            }
        },

        '$root.deliveryInfo.price': function () {
            this.actualaizeBasketDebounce()
        },

        '$root.basket.cart': function (cart) {
            const clientHeight_new = document.querySelector('.container .basket-items').clientHeight;

            if (global.clientHeight_old) {
                document.body.parentElement.style.scrollBehavior = 'auto';
                window.scrollTo(0, window.scrollY + (clientHeight_new - global.clientHeight_old));
                document.body.parentElement.style.scrollBehavior = null;
            }

            global.clientHeight_old = clientHeight_new;

            if (cart.count === 0) {
                window.location = '/'
                return
            }

            this.$nextTick(() => {
                this.checkConditionsPromocode();
                if (this.$root.uds.applied) {
                    this.applyUds(this.$root.uds.key, this.$root.uds.receipt.points);
                }
            });
        },

        appliedPromocode() {
            this.$root.promocode = {
                key: '',
                error: null
            };
        },

        '$root.uds.receipt.points'(value) {
            if (this.$root.uds.purchase.certificatePoints) value = this.$root.uds.purchase.maxPoints;

            this.$root.uds.receipt.points = Math.min(+`${value}`.replace(/\D+/g, ''), this.$root.uds.purchase.maxPoints);
            this.$root.uds.purchase.cashBack = (this.payload.payment.total * 0.01 * this.$root.uds.customer.cashbackRate).toFixed(2);
            this.$root.uds.receipt.cash = this.$root.uds.purchase.total - this.$root.uds.receipt.points
        },

        '$root.uds.key'(value, oldValue) {
            if (value.length === 7 && oldValue.length === 6) {
                this.applyUds(this.$root.uds.key, this.$root.uds.receipt.points);
            }
        },

        'promocode.key'(value, oldValue) {
            if (value.length === 6 && oldValue.length === 5 && /\d{6}/u.test(value)) {
                this.applyPromocode(this.$root.promocode.key);
            }
        },

        '$root.orderType'(value) {
            if (this.$root.uds.applied) this.applyUds(this.$root.uds.key, this.$root.uds.receipt.points);
            this.checkConditionsPromocode();
            this.actualaizeBasketDebounce()

            if (value === 'takeaway' && this.shop.id) {
                this.getCustomCookingTime(this.shop.id)
                    .then(({data}) => this.orderReadyTimeShop = data.minutes);
            }
        },
        '$root.shop.id'(shopId) {
            this.checkPromocode();
            this.getCustomCookingTime(shopId)
                .then(({data}) => this.orderReadyTimeShop = data.minutes);
        }
    }
}
</script>

<style lang="scss" scoped>
    .form-group--uds label {
        max-width: 420px;
    }

    .block-group__label-active {
        top: -10px;
        font-size: 10px;
    }

    .form-group--promocode {
        max-width: 420px;

        input::placeholder {
            @media (max-width: 950px) {
                font-size: 12px;
            }
        }
    }
    .rules-for-using-certificate {
        @media (max-width: 950px) {
            display: none;
        }
    }
    .cash-button {
        width: 100px;
        font-size: 14px;
        padding: 18px 0;
        @media (max-width: 950px) {
            width: 80px;
            font-size: 14px;
        }
    }
    form {
        @media (min-width: 950px) {
            display: grid;
            grid-template-areas:
                'order-type order-type'
                'delivery-info delivery-info'
                'pickup-time pickup-time'
                'uds uds'
                'promocode promocode'
                'payment payment'
                'cash cash'
                'privacy-policy privacy-policy'
                'create-order .';
        }

        .checkout__order-type {
            grid-area: order-type;
        }

        .form-group {
            &--delivery-info {
                grid-area: delivery-info;
            }

            &--pickup-time {
                grid-area: pickup-time;
            }

            &--payment {
                grid-area: payment;
            }

            &--cash {
                max-width: 420px;
                grid-area: cash;
                display: grid;
                gap: 6px;

                @media (max-width: 950px) {
                    grid-template-columns: none;
                    grid-template-rows: auto;
                }
            }

            &--cash-buttons {
                display: grid;
                gap: 6px;
                justify-content: space-between;
                grid-template-columns: repeat(auto-fill, 100px);

                @media (max-width: 950px) {
                    grid-template-columns: repeat(auto-fill, 80px);
                }

            }

            &--errors {
                grid-area: errors;
            }

            &--create-order {
                grid-area: create-order;
            }

            &--promocode {
                grid-area: promocode;
            }

            &--uds {
                grid-area: uds;
            }
        }
    }

    form.submitted:deep(*) {
        *:invalid {
            &, &::before, & ~ .address-select-input {
                border-color: var(--color-red);
            }
        }

        *:valid {
            &, &::before, & ~ .address-select-input {
                border-color: var(--color-green);
            }
        }
    }

    form:deep(.payment-list), form:deep(.form-group--pickup-time) {
        label > input[required] ~ span::after {
            display: none;
        }
    }
</style>
