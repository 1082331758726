<template>
    <div class="modal-base" :style="{'display': visible ? 'block' : 'none'}">
        <div class="modal-base__content">
            <div class="modal-base__close" @click="close">
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="32" height="32" rx="16" fill="white"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M22.7071 10.7071C23.0976 10.3166 23.0976 9.68342 22.7071 9.29289C22.3166 8.90237 21.6834 8.90237 21.2929 9.29289L16 14.5858L10.7071 9.29289C10.3166 8.90237 9.68342 8.90237 9.29289 9.29289C8.90237 9.68342 8.90237 10.3166 9.29289 10.7071L14.5858 16L9.29289 21.2929C8.90237 21.6834 8.90237 22.3166 9.29289 22.7071C9.68342 23.0976 10.3166 23.0976 10.7071 22.7071L16 17.4142L21.2929 22.7071C21.6834 23.0976 22.3166 23.0976 22.7071 22.7071C23.0976 22.3166 23.0976 21.6834 22.7071 21.2929L17.4142 16L22.7071 10.7071Z" fill="#8A8A8A"/>
                </svg>
            </div>
            <div class="modal-base__title">{{ title }}</div>
            <div class="modal-base__text">
                <slot name="text" />
            </div>
            <div class="modal-base__buttons">
                <slot name="buttons" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: ''
        }
    },
    watch: {
        visible(val) {
            this.$nextTick(() => {
                document.body.style.overflow = val ? 'hidden' : '';
            });
        }
    },
    methods: {
        close() {
            this.$emit('close');
        }
    }
}
</script>


<style scoped lang="scss">
.modal-base {
    position: fixed;
    z-index: 20001;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: rgba(0,0,0,0.4);
}

.modal-base__content {
    background-color: #fefefe;
    margin: 15% auto;
    padding: 22px;
    border: none;
    position: relative;
    border-radius: 20px;
    width: 326px;
}

.modal-base__close {
    color: #aaa;
    font-size: 30px;
    position: absolute;
    right: 5px;
    top: 5px;
}

.modal-base__close,
.modal-base__close svg,
.modal-base__close rect,
.modal-base__close path {
    cursor: pointer;
}

.modal-base__title {
    margin-top: 34px;
    font-size: 20px;
    font-weight: 500;
    font-family: 'Roboto', sans-serif;
    line-height: 20px;
}

.modal-base__text {
    margin-top: 24px;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
}

.modal-base__buttons {
    margin-top: 35px;
    display: flex;
    gap: 5px;
}

.modal-base__button {
    width: 100%;
    background-color: #00BF53;
    color: white;
    font-size: 20px;
    font-weight: 500;
    padding: 13px 0;
    text-align: center;
    border-radius: 60px;
    cursor: pointer;
}

.modal-base__button:hover {
    transition: .5s;
}
</style>
