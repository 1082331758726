<template>
    <div :class="$options.name">
        <label v-for="payment in payments" :key="payment.id">
            <input type="radio" :value="payment.slug" :checked="value && payment.slug === value.slug" @input="$emit('input', payment)" required>
            <span>{{ payment.name }}</span>
        </label>
    </div>
</template>

<script>
import '../../../../sass/components/payment-list.scss';

export default {
    name: 'payment-list',
    props: ['value', 'payments'],
}
</script>
