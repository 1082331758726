<template>
    <div class="product">
        <a :href="`/product/${product.translitAlias}`" @click.prevent>
            <div class="product__hover-image-scale" :class="{ 'img--contain': product.group === 9 }" :data-alt="product.name" ref="image" @click="$root.openModal('product', { product })">
                <img :src="product.img.big" class="img product__hover-image-scale" alt="">
                <div class="product-label">
                    <div v-for="label in product.labels" :key="label" :class="`product-label__item product-label__item--${label}`"></div>
                </div>
            </div>
        </a>

        <div class="product-info__wrapper">
            <a :href="`/product/${product.translitAlias}`" @click.prevent class="product__title link link--black" @click="$root.openModal('product', { product })">{{ product.name }}</a>
            <div class="product__meta">{{ product.quantityInProduct }} шт / {{ product.weight.value }} {{ product.weight.unit }}</div>
            <product-counter v-if="$root.window.width < 551 && currentBasket"
                             :product="currentBasket" />
            <div v-else class="product__add-to-cart btn--success"
                 @click="product.groupModifiers.length || product.modifiers.length ? $root.openModal('product', { product }) : beforeAddToCart()"
                 :class="{'btn--processing': processing}">
                {{ product.price }} ₽
            </div>
        </div>
    </div>
</template>

<script>
    import productImgDefault from '../../../images/placeholder-yapdomik.svg';
    import * as Sentry from '@sentry/browser';
    import { getStopList } from "../../axios/axios_stop-list";

    export default {
        props: ['product', 'type', 'currency'],
        data() {
            return {
                selectedProductId: null,
                cartModal: false,
                processing: false,
                productImgDefault
            }
        },
        methods: {
            getStopList,
            //Открытие модалки расширенной карточки товара
            cardProduct() {
                this.cartModal=true;
            },

            beforeAddToCart() {
                if (this.$root.cacheStorage('get', 'deliveryAddress') || this.$root.cacheStorage('get', 'shop')) {
                    this.addToCart(1);
                    this.selectedProductId = null;
                    return;
                }

                this.selectedProductId = this.product.id;

                this.$root.openModal('select-delivery-address');
            },

            addToCart(count = 1) {
                if (this.processing) return;

                this.processing = true;
                this.$root.basket.loaded = false;

                axios.post('/basket/add', {
                    productId: this.product.id,
                    count
                })
                .then((response) => {
                    this.animationToCart();
                    return response;
                })
                .then((response) => {
                    this.$root.basket.cart = response.data.cart;
                    this.$root.basket.requirements = response.data.requirements;
                    this.$root.basket.loaded = true;
                    window.localStorage.setItem('cartHash', response.data.cart.hash)
                })
                .then(() => {
                    this.$eventBus.$emit('add-to-cart');
                })
                .catch((error) => {
                    if (error.message === 'canceled') {
                        return alert('Упс... Кажется у вас отключены cookie! Мы не сможем гарантировать работу сайта, пока вы их не включите :(')
                    }

                    Sentry.captureException(error);

                    const errors = error.response?.data?.errors || [{message: 'Не удалось добавить товар. Попробуйте еще раз'}];

                    alert(errors.map((item) => item.message).filter((item) => item).join('\n'));

                    if (errors.find((item) => item.code === 300)) {
                        location.reload();
                    }
                })
                .then(() => {
                    this.processing = false;
                    this.$root.basket.loaded = true;
                });
            },

            animationToCart() {
                const image = this.$refs.image;

                const imgOnBasket = $(`<img alt="${this.product.name}" src="${this.product.img.big}" />`)
                    .css({
                        position: 'absolute',
                        'z-index': 100,
                        opacity: 0.7,
                        height: 'auto',
                        width: image.width,
                        top: image.offsetTop,
                        left: image.offsetLeft
                    })
                    .insertAfter(image);

                imgOnBasket.one('load', () => {
                    const $cartButton = $('.mini-basket .mini-basket__button');
                    const cartButtonOffset = $cartButton.offset();

                    $(imgOnBasket).animate({
                        opacity: 0.1,
                        marginLeft: 0,
                        left: cartButtonOffset.left + $cartButton.width() / 2,
                        top: cartButtonOffset.top + $cartButton.height() / 2,
                        width: 22,
                        height: 15
                    }, 650, () => $(imgOnBasket).remove());
                });
            }
        },
        watch: {
            // Проверка для города при вызове модалки и добавлении в корзину
            '$root.addr.status'(val) {
                if (val === 'delivered' && this.selectedProductId && this.$root.orderType === 'delivery') {
                    this.getStopList()
                        .then(({data}) => {
                            this.$root.stopList = data.result;

                            if (this.$root.stopList.delivery.products[this.$root.city.id]
                                && this.$root.stopList.delivery.products[this.$root.city.id].includes(this.selectedProductId)) {
                                this.$parent.dialogError.visible = true;
                                this.$parent.dialogError.title = 'Ошибка продукта';
                                this.$parent.dialogError.message = 'Извините, но продукт находится в стоп-листе';
                            } else {
                                this.addToCart(1);
                            }
                        }).finally(() => this.selectedProductId = null);
                }
            },
            // Проверка для торговых точек при вызове модалки и добавлении в корзину
            '$root.shop.id'(shopId) {
                if (shopId && this.selectedProductId && this.$root.orderType === 'takeaway') {
                    this.getStopList()
                        .then(({data}) => {
                        this.$root.stopList = data.result;

                        if (this.$root.stopList.pickup.products[shopId] && this.$root.stopList.pickup.products[shopId].includes(this.selectedProductId)) {
                            this.$parent.dialogError.visible = true;
                            this.$parent.dialogError.title = 'Ошибка продукта';
                            this.$parent.dialogError.message = 'Извините, но продукт находится в стоп-листе';
                        } else {
                            this.addToCart(1);
                        }
                    }).finally(() => this.selectedProductId = null);
                }
            }
        },
        computed: {
            isShowSelectDelivery() {
                return this.$root.modal.open.name === 'select-delivery-address';
            },
            isEmptyStorage() {
                return this.$root.cacheStorage('get', 'deliveryAddress') || this.$root.cacheStorage('get', 'shop');
            },
            currentBasket() {
                return this.$root.cartProductsSorted.find((product) => product.id === this.product.id);
            }
        }
    }
</script>
