<template>
    <div class="headerYandexPickupMap__shop">
        <div class="headerYandexPickupMap__shop--title">{{ shop.address }}</div>
        <div class="headerYandexPickupMap__shop--cooking-time">
            <span class="headerYandexPickupMap__shop--cooking-time-title">Текущее время ожидания</span>
            <div class="headerYandexPickupMap__shop--cooking-time-minute">{{ shop.customCookingTime.minutes }} {{ declOfNum(shop.customCookingTime.minutes, ['минута', 'минуты', 'минут']) }}</div>
        </div>
        <div class="headerYandexPickupMap__shop--work-time"
             @click="isShow = !isShow">
            <p>
                <svg style="margin-top: 5px" width="8"
                     height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="4" cy="4" r="4" :fill="isTimeBetween(shop.workingTime[0].from, shop.workingTime[0].to) ? '#00BF53' : '#f00'"/>
                </svg>
                {{ isTimeBetween(shop.workingTime[0].from, shop.workingTime[0].to) ?
                    `Открыто до ${shop.workingTime[0].to}`
                    : `Закрыто до ${shop.workingTime[0].from}`
                }}
                <svg width="16"  height="16"
                     :style="{'transform': isShow ? 'rotate(180deg)' : 'rotate(360deg)'}"
                     viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 6.00248L7.99752 10L4 6.00248" stroke="#333333" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </p>
            <ul class="headerYandexPickupMap__shop--all-work-time"
                :style="{'display': isShow ? 'block' : 'none'}">
                <li>Ежедневно {{ `${shop.workingTime[0].from} - ${shop.workingTime[0].to}` }}</li>
                <li>Приём заказов с {{ `${shop.onlineOrder[0].from} до ${shop.onlineOrder[0].to}` }}</li>
            </ul>
        </div>
        <button class="headerYandexPickupMap__shop--select-shop"
                :class="{'headerYandexPickupMap__shop--selected-shop': $root.shop?.id === shop.id }"
                @click="$emit('onClickShop', shop)">{{ $root.shop?.id === shop.id ? 'Выбрано' : 'Выбрать' }}</button>
    </div>
</template>

<script>
import { declOfNum } from "../../mixins/app/declOfNum";
import { isTimeBetween } from "../map/ballonForShop";

export default {
    props: {
        shop: {
            type: Object,
            default() {
                return {};
            }
        }
    },
    data() {
        return {
            isShow: false
        }
    },
    computed: {
        moment() {
            return window.moment;
        }
    },
    methods: {
        isTimeBetween,
        declOfNum
    }
}
</script>

<style scoped>
    .active {
        display: block;
    }
</style>
