<template>
    <div id="takeawayMap" class="takeawayMap" ref="map"></div>
</template>

<script>
import { addTakeawayShops } from "./ballonForShop";

export default {
    data() {
        return {
            map: null
        }
    },
    methods: {
        addTakeawayShops,
        fitToContainer() {
            if (this.map) {
                this.$nextTick(() => {
                    this.map.container.fitToViewport();
                });
            }
        },
        initMap() {
            ymaps.ready(() => {
                let coords = Object.values(this.$root.city.coord);
                let isCityCoords = true

                if (this.$root.addr.coordinates && this.$root.addr.coordinates.latitude && this.$root.addr.coordinates.longitude) {
                    isCityCoords = false
                    coords = [ this.$root.addr.coordinates.latitude, this.$root.addr.coordinates.longitude ]
                }

                this.map = new ymaps.Map(this.$refs.map, {
                    center: coords,
                    zoom: 17,
                    autoFitToViewport: 'always',
                    controls: [],
                }, {suppressMapOpenBlock: true});

                let zoomControl = new ymaps.control.ZoomControl({
                    options: {
                        size: "small",
                        position: {
                            right: 18,
                            top: 108
                        }
                    }
                });

                let geolocationControl = new ymaps.control.GeolocationControl({
                    options: {
                        position: {
                            right: 18,
                            top: 190
                        }
                    }
                });
                this.map.controls.add(zoomControl);
                this.map.controls.add(geolocationControl);
                this.addTakeawayShops(this.map, this);

                this.getDeliveryZones(ymaps, this.map, true);

                if (this.$root.shop.id) {
                    this.selectShop(this.$root.shop.id);
                }
            });
        },
        selectShop(shopId, isClosing = false) {
            const shop = this.$root.shops.find((shop) => shop.id === Number(shopId));
            this.$root.shop = shop;
            this.$root.cacheStorage('set', 'shop', shop);

            if (this.$parent.isShow) {
                this.$nextTick(() => {
                    this.openActiveShopBallon(shop);
                });
            }

            if (isClosing) {
                this.$parent.isShowShopList = false;
                this.$root.closeModal(this.$parent.$options.name);
            }
        },
        openActiveShopBallon(shop) {
            this.fitToContainer();

            this.map.geoObjects.each((object) => {
                if (object instanceof ymaps.ObjectManager) {
                    const currentShop = object.objects.getById(shop.id);
                    currentShop.properties.isActive = true;
                    object.objects.balloon.close(shop.id);
                    this.map.setCenter([ shop.coord.latitude, shop.coord.longitude ], 15);
                }
            });
        },
    },
    mounted() {
        if (!this.map) {
            this.initMap();
        }
    }
}
</script>
