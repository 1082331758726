<template>
    <div :class="$options.name">
        <label v-for="input in inputs" :key="input.value">
            <input type="radio" :name="name" :value="input.value" :checked="input.value === value" @input="$emit('input', $event.target.value)">
            <span>{{ input.label }}</span>
        </label>
    </div>
</template>

<script>
export default {
    name: 'radio-slider',
    props: [ 'value', 'name', 'inputs' ]
}
</script>
