<template>
    <div :class="$options.name">
        <select :name="name" v-model="selectedValue" v-show="false" required>
            <option></option>
            <option v-for="shop in shops" v-if="!shop.only_delivery" :key="shop.id" :value="shop.id">{{ shop.address }}</option>
        </select>

        <template v-if="shops.length">
            <div class="address-select-input" @click="toggleOptions">
                <span class="address-select-input__value" v-if="selectedShop">{{ selectedShop.address }}</span>
                <span class="address-select-input__placeholder">Выберите адрес</span>
            </div>

            <div class="address-select-options" v-show="optionsIsOpen">
                <div v-for="shop in shops"  v-if="!shop.only_delivery" :key="shop.id" :class="{ 'address-select-options__item--selected': selectedValue === shop.id, 'address-select-options__item--closed': shop.closed }" class="address-select-options__item" @click="select(shop.id)">
                    <div class="address__label"><template v-if="shop.closed"><b>Закрыто </b></template>{{ shop.address }}</div>
                    <div class="address__point">{{ shop.geoPoint }}</div>
                    <div class="address__open">Открыто с {{ shop.workingTime[0].format('HH:mm') }} до {{ shop.workingTime[1].format('HH:mm') }}</div>
                </div>
            </div>
        </template>

        <template v-else>
            <div style="color: red;">Самовывоз недоступен</div>
        </template>
    </div>
</template>

<script>
export default {
    name: 'address-select',
    props: [ 'value', 'name' ],

    data: function () {
        return {
            selectedValue: this.$props.value,
            optionsIsOpen: false
        };
    },

    methods: {
        showOptions() {
            this.optionsIsOpen = true;
        },

        closeOptions() {
            this.optionsIsOpen = false;
        },

        toggleOptions() {
            this.optionsIsOpen = !this.optionsIsOpen;
        },

        select(value) {
            this.selectedValue = value;
            this.$emit('input', this.selectedValue);
            this.closeOptions();
        },

        getWorkingTime(shop) {
            const momentNow = window.moment(window.utcDate).utcOffset(this.$root.city.timeZoneOffsetInHours);

            const workingHoursCurrent = shop.workingHours
                .filter((item) => item.type === 'default')
                .filter((item) => item.date === null)
                .find((item) => {
                    const startOfDay = momentNow.clone().startOf('day');
                    startOfDay.add(item.day - startOfDay.format('E'), 'days');

                    const momentFrom = startOfDay.clone().add(item.from, 'minutes');
                    const momentTo = startOfDay.clone().add(item.to, 'minutes');

                    return momentNow.isBetween(momentFrom, momentTo, 'minutes', '[]');
                })

            const workingHoursOfDay = shop.workingHours
                .filter((item) => item.type === 'default')
                .filter((item) => item.date === null)
                .find((item) => item.day === +momentNow.format('E'));

            const workingHours = workingHoursCurrent || workingHoursOfDay;
            const startOfDay = momentNow.clone().startOf('day');

            return [
                startOfDay.clone().add(workingHours?.from ?? 0, 'minutes'),
                startOfDay.clone().add(workingHours?.to ?? 1439, 'minutes')
            ]
        },

        checkWorkingTime(workingTime) {
            const dateNow = window.moment(window.utcDate).utcOffset(this.$root.city.timeZoneOffsetInHours)
            return dateNow.isBetween(workingTime[0], workingTime[1])
        }
    },

    computed: {
        shops() {
            return this.$root.shops.map(shop => {
                shop.workingTime = this.getWorkingTime(shop)
                shop.closed = !this.checkWorkingTime(shop.workingTime)
                return shop
            }).sort(a => {
                return a.closed ? 1 : -1
            })
        },

        selectedShop() {
            return !this.selectedValue ? null : this.shops.find((shop) => shop.id === this.selectedValue);
        }
    },

    watch: {
        'selectedValue'() {
            this.$emit('change');
        }
    }
}
</script>

<style scoped>
.address-select-options__item--closed {
    background-color: var(--color-red-20);
}
</style>
