<template>
    <div class="dropdown_menu">
        <div class="select_menu" :class="{'select-clicked': isShow}" @click="clickSelect">
            <img :src="`/images/icons/payment/${currentItem.slug}.svg`" v-if="isPayment"  :alt="currentItem.slug"/>
            <span class="selected">{{ currentItem.name }}</span>
            <div class="caret" :class="{'caret-rotate': isShow}">
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M22 13.2542L15.496 20L9 13.2542" stroke="#00BF53" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>
            <hr v-show="isTime && isShow" class="select_menu__border-bottom"/>
        </div>
        <ul class="menu" :style="styleMenu"
            :class="{'menu-open': isShow, 'menu__is-time': isTime}">
            <li v-for="item in showItems"
                :style="styleLi"
                :class="{'menu__li-is-time': isTime}"
                @click="clickItem(item)">
                <img :src="`/images/icons/payment/${item.slug}.svg`" :alt="item.slug" v-if="isPayment && item.slug">
                <span>{{ item.name }}</span>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'dropdown-menu',
    props: {
        items: {
            type: Array,
            default() {
                return [];
            }
        },
        isTime: {
            type: Boolean,
            default() {
                return false;
            }
        },
        isPayment: {
            type: Boolean,
            default() {
                return false;
            }
        },
        keyValue: {
            type: String,
            default() {
                return null;
            }
        }
    },
    data() {
        return {
            currentItem: {},
            isShow: false
        }
    },
    computed: {
        styleMenu() {
            if (!this.isTime) {
                return {};
            }

            return {
                'maxHeight': '184px',
                'overflowY': 'scroll'
            };
        },
        styleLi() {
            if (!this.isTime) {
                return {};
            }

            return {
                'padding': '9px 0'
            }
        },
        showItems() {
            if (this.items.length === 0) {
                return [];
            }
            return this.items.filter((item) => item.id !== this.currentItem.id);
        },
        isEmptyCurrentItem() {
            return Object.keys(this.currentItem).length === 0;
        },
        isEmptyItems() {
            return this.items.length === 0;
        },
    },
    watch: {
        '$attrs.value'(value) {
            if (this.isPayment && value) {
                this.currentItem = value;
            }

            if (this.isTime && value) {
                let lastValue = this.showItems.find((item) => item.value === value);

                if (lastValue) {
                    this.currentItem = lastValue;
                }
            }
        },
        items(value, oldValue) {
            if (value.toString() === oldValue.toString()) {
                return;
            }

            let isValueExists = value.find((item) => item.value === this.currentItem.value);

            if (this.isTime && !isValueExists) {
                this.currentItem = this.items[0];
            }
        }
    },
    methods: {
        getIcon(slug) {
            return `<img src="../../../images/icons/payment/${slug}.svg" :alt="item.slug" v-if="isPayment">`;
        },
        clickSelect() {
            if (this.isEmptyItems) {
                return;
            }
            this.isShow = this.isShow !== true;
        },
        clickItem(currentItem) {
            this.isShow = false;
            this.currentItem = currentItem;
            this.$emit('change', this.currentItem);

            this.getInput();
        },
        getInput() {
            if (!this.keyValue) {
                this.$emit('input', this.currentItem);
                return;
            }

            this.$emit('input', this.currentItem[this.keyValue]);
        }
    },
    mounted() {
        if (this.isEmptyItems) {
            return;
        }

        if (this.items[0]) {
            this.currentItem = this.items[0];

            this.getInput();
        }
    }
}
</script>

<style scoped>
.address-danger {
    padding: 5px;
    border-radius: 10px;
    background-color: red;
    color: white;
}

.dropdown_menu {
    position: relative;
}

.dropdown_menu * {
    box-sizing: border-box;
}
.select_menu {
    width: 100%;
    height: 48px;
    outline: none;
    padding: 5px 15px 5px 24px;
    border: 1px solid rgb(212, 212, 212);
    border-radius: 60px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: background 0.3s;
}

.select-clicked {
    border-radius: 25px 25px 0 0;
    border-bottom: none;
}

.caret {
    transition: 0.3s;
    margin-left: auto;
}

.caret-rotate {
    transform: rotate(180deg);
}

.menu {
    list-style: none;
    padding: 0 24px;
    background-color: white;
    border: 1px solid rgb(212, 212, 212);
    border-radius: 0.5em;
    color: black;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    margin-top: -1px;
    display: none;
    transition: 0.5s;
    z-index: 1;
}

.menu li {
    display: flex;
    align-items: center;
    padding: 15px 0;
    cursor: pointer;
    border-top: 1px solid rgb(212, 212, 212);
}

.menu__li-is-time {
    border-radius: 60px;
    border-top: none !important;
    padding: 9px 0 9px 12px !important;
}

.menu__li-is-time:hover {
    background-color: rgba(235, 235, 235, 1);
}

.menu__is-time {
    padding: 0 12px;
}

.active {
    background: #23242a;
}

.menu-open {
    display: block;
    border-top: none;
    opacity: 1;
    border-radius: 0 0 25px 25px;
    width: 100%;
}

.select_menu__border-bottom {
    position: absolute;
    z-index: 10000;
    left: 20px;
    top: 47px;
    width: 85%;
    margin: 0;
}


</style>
