export default {
    data() {
        return {
            addr: {
                modal: false,
                status: 'search',
                found: false,
                delivered: false,
                selected: false,

                coordinates: {
                    latitude: null,
                    longitude: null,
                },

                formated: '',
                street: '',
                house: '',
                room: '',
                floor: '',
                entrance: '',
                intercom: '',
            },
            unavailableClientAddress: {
                show: false,
                save: false,
            }
        }
    },
    methods: {
        setDelivery(deliveryAddress) {
            this.loadedCache = true;
            this.userLocation = deliveryAddress.coordinates ?? this.userLocation;

            this.addr.selected = true;
            this.addr.status = deliveryAddress.status ?? this.addr.status;
            this.addr.modal = deliveryAddress.modal ?? this.addr.modal;
            this.addr.delivered = deliveryAddress.delivered ?? this.addr.delivered;

            if (deliveryAddress.coordinates && deliveryAddress.coordinates.latitude) {
                this.addr.coordinates = {
                    latitude: deliveryAddress.coordinates.latitude,
                    longitude: deliveryAddress.coordinates.longitude
                };
            }

            this.addr.formated = deliveryAddress.formated ?? this.addr.formated;
            this.addr.street = deliveryAddress.street ?? this.addr.street;
            this.addr.house = deliveryAddress.house ?? this.addr.house;
            this.addr.room = deliveryAddress.room ?? this.addr.room;
            this.addr.floor = deliveryAddress.floor ?? this.addr.floor;
            this.addr.entrance = deliveryAddress.entrance ?? this.addr.entrance;
            this.addr.intercom = deliveryAddress.intercom ?? this.addr.intercom;
            this.addr.comment = deliveryAddress.comment ?? this.addr.comment;

            return this.addr;
        },
        clearUnavailableClientAddress() {
            this.unavailableClientAddress.show = false;
            this.unavailableClientAddress.save = false;
        },
        saveUnavailableClientAddress() {
            this.unavailableClientAddress.save = true;
            axios.post('/api/unavailableClientAddress', {
                city_id: this.city.id,
                coordinates: this.addr.coordinates,
                street: this.addr.street,
                house: this.addr.house,
                phone: this.$root.phone
            });
        },
        clearDataOfAddress() {
            this.addr.found = false
            this.addr.coordinates.latitude = null
            this.addr.coordinates.longitude = null
            this.addr.formated = ''
            this.addr.street = ''
            this.addr.house = ''
            this.addr.room = ''
            this.addr.floor = ''
            this.addr.entrance = ''
            this.addr.intercom = ''
        }
    },
    computed: {
        stringAddress() {
            let stringAddress = '';

            if (!this.$root.city.name || !this.$root.addr.formated) return stringAddress;

            stringAddress += this.$root.city.name + ', ';
            stringAddress += this.$root.addr.formated;
            if (this.$root.addr.entrance) {
                stringAddress += ', подъезд: ' + this.$root.addr.entrance;
            }
            if (this.$root.addr.floor) {
                stringAddress += ', этаж: ' + this.$root.addr.floor;
            }
            if (this.$root.addr.room) {
                stringAddress += ', квартира/офис: ' + this.$root.addr.room;
            }

            if (this.$root.addr.intercom) {
                stringAddress += ', код: ' + this.$root.addr.intercom;
            }

            return stringAddress;
        },
    }
}
