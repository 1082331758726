<template>
    <div class="alert alert--error">Мы временно приостановили прием заказов с сайта из-за высокой нагрузки, зайдите, пожалуйста, позднее.</div>
</template>

<script>
export default {
    name: 'alert-city-stop'
}
</script>

<style scoped>
.alert {
    font-weight: 500;
    font-size: 24px;

    padding: 30px;
}
</style>
