<template>
    <div class="basket">
        <div class="basket-info__change_amount" :class="{ 'basket-info__change_amount--active': !$root.isChangeTotal }">
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" @click="$root.isChangeTotal = false">
                <rect width="32" height="32" rx="16" fill="white"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M22.7071 10.7071C23.0976 10.3166 23.0976 9.68342 22.7071 9.29289C22.3166 8.90237 21.6834 8.90237 21.2929 9.29289L16 14.5858L10.7071 9.29289C10.3166 8.90237 9.68342 8.90237 9.29289 9.29289C8.90237 9.68342 8.90237 10.3166 9.29289 10.7071L14.5858 16L9.29289 21.2929C8.90237 21.6834 8.90237 22.3166 9.29289 22.7071C9.68342 23.0976 10.3166 23.0976 10.7071 22.7071L16 17.4142L21.2929 22.7071C21.6834 23.0976 22.3166 23.0976 22.7071 22.7071C23.0976 22.3166 23.0976 21.6834 22.7071 21.2929L17.4142 16L22.7071 10.7071Z" fill="#8A8A8A"/>
            </svg>
            <span>Стоимость в корзине изменилась</span>
        </div>
        <div class="basket-info">
            <div class="basket-info__count">
                {{ countAll }} {{ declOfNum(countAll, ['товар', 'товара', 'товаров']) }} в корзине
            </div>
            <div class="basket-info__clear" @click="clearCart">
                Очистить корзину
            </div>
        </div>

        <div class="basket-items">
            <div class="loader" v-show="loading"></div>
            <div v-for="product in products" class="basket-item">
                <div class="basket-item__image-wrapper">
                    <img class="basket-item__image" :src="product.img.big" :alt="product.name" height="165">
                    <div class="product-label">
                        <div v-for="label in product.labels" :key="label" :class="`product-label__item product-label__item--${label}`"></div>
                    </div>
                </div>
                <div class="basket-item__title">{{ product.name }}</div>
                <div class="basket-item__count_and_price">
                    <product-counter :product="product"></product-counter>
                    <div class="basket-item__price currency currency--top">{{ product.price * product.count | numberFormat }}</div>
                </div>
                <div class="basket-item__clear" @click="$eventBus.$emit('sendCart', { type: 'forget', productId: product.index })">
                    <div>
                        <svg style="cursor: pointer" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.7071 1.70711C14.0976 1.31658 14.0976 0.683417 13.7071 0.292893C13.3166 -0.0976311 12.6834 -0.0976311 12.2929 0.292893L7 5.58579L1.70711 0.292893C1.31658 -0.0976311 0.683417 -0.0976311 0.292893 0.292893C-0.0976311 0.683417 -0.0976311 1.31658 0.292893 1.70711L5.58579 7L0.292893 12.2929C-0.0976311 12.6834 -0.0976311 13.3166 0.292893 13.7071C0.683417 14.0976 1.31658 14.0976 1.70711 13.7071L7 8.41421L12.2929 13.7071C12.6834 14.0976 13.3166 14.0976 13.7071 13.7071C14.0976 13.3166 14.0976 12.6834 13.7071 12.2929L8.41421 7L13.7071 1.70711Z" fill="#EBEBEB"/>
                        </svg>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { declOfNum } from "../../mixins/app/declOfNum";

export default {
        props: ['products'],
        watch: {
            total() {
                this.$root.isChangeTotal = true;
            }
        },
        methods: {
            declOfNum,
            clearCart() {
                this.$eventBus.$emit("sendCart", {
                    type: 'clear'
                });
            },
            statsMetrics(name) {
                this.$root.statsMetrics(name);
            },
            forgetCart(id) {
                this.$eventBus.$emit("sendCart", {
                    type: 'forget',
                    productId: id
                });
            },
            modalOpen(name) {
                document.winLoad(() => {
                    this.$root.openModal('selectOrderType', name);
                })
            },
            getAllModifiers(product) {
                return [...product.modifiers.filter((modifier) => modifier.value), ...product.groupModifiers.reduce((carry, groupModifiers) => {
                    return [...carry, ...groupModifiers.modifiers.filter((modifier) => modifier.value)];
                }, [])];
            },
        },
        computed: {
            disabledDelivery() {
                return this.loading;
            },
            emptyBasket() {
                if (this.loadedBasket === false) {
                    return false;
                }

                return this.basket.cart.count === 0
                    && (this.basket.cart.unavailableGoods === undefined || Object.keys(this.basket.cart.unavailableGoods).length === 0);
            },
            loadedBasket() {
                return this.basket.loaded;
            },
            total() {
                return this.$root.basket.cart.price.total;
            },
            loading() {
                return !this.$root.basket.loaded;
            },
            countAll() {
                return this.$root.basket.cart.countAll;
            }
        },
    }
</script>
