export default {
    methods: {
        // Функция отправки метрики
        statsMetrics(name, data = {}) {
            try {
                ym(this.$root.metrics.yandex_metrika_key, 'reachGoal', name);
                gtag('event', name);

                if (name == 'in_order') {
                    fbq('track', 'InitiateCheckout');
                    window.VK.Retargeting.Event('start_order');
                }

                if (name == 'in_basket') {
                    fbq('trackCustom', 'PereytiVKorzinu');
                    window.VK.Retargeting.Event('go_in_basket');
                }

                if (name == 'pick_call') {
                    fbq('trackCustom', 'ZakazatZvonok');
                    window.VK.Retargeting.Event('take_call');
                }

                if (name == 'pick_product') {
                    fbq('track', 'AddToCart');
                    window.VK.Retargeting.Event('pick_in_basket');
                }

                if(name == 'create_order') {
                    fbq('track', 'Purchase', data);
                    window.VK.Retargeting.Event('creat_order');
                }
            } catch (error) {
                // console.error(error)
            }
        },
    }
}
